<template>
  <div>
    <div>
      <img
        src="../img/NLC new website/vector.png"
        alt="vector-img"
        id="vector"
      />
      <p style="color: #dc6803; padding-top: 5%">{{ $t("Contact[0]") }}</p>
      <b style="font-size: 40px">{{ $t("Contact[1]") }}</b>
      <br /><br /><br />
      <b style="font-size: 30px">{{ $t("Contact[2][0]") }}</b>
      <br />
      <p style="font-size: 25px">
        {{ $t("Contact[2][1]") }}
      </p>
      <br />
      <b style="font-size: 30px">{{ $t("Contact[3][0]") }}</b>
      <ul style="font-size: 25px">
        <li>
          {{ $t("Contact[3][1]") }}
        </li>
        <li>{{ $t("Contact[3][2]") }}</li>
        <li>
          {{ $t("Contact[3][3]") }}
        </li>
      </ul>

      <br /><br /><br />
      <div id="contact_bg">
        <table>
          <tr>
            <td>
              <div style="width: 100%">
                <b style="color: #831b19; font-size: 40px">{{
                  $t("Contact[4][0]")
                }}</b>
                <img src="../img/girl_open_door.png" alt="" id="IMG" />

                <br /><br />
                <p id="contact_bg_content">
                  {{ $t("Contact[4][1]") }}
                </p>
                <br /><br />
              </div>
            </td>
          </tr>
        </table>
      </div>
      <br /><br />
    </div>
    <ContactUs id="contactformContact" />
  </div>
</template>
<script>
import ContactUs from "@/components/contactUs.vue";
export default {
  components: {
    ContactUs,
  },
  mounted: function () {
    this.goTop();
  },
  methods: {
    goTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
#contact_bg {
  background-image: url("../img/NLC new website/contact_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5%;
}

td {
  border: none;
  vertical-align: top;
  width: 50%;
}
#vector {
  width: 7%;
  height: fit-content;
  position: absolute;
  left: 0;
  top: 10%;
}
#IMG {
  float: right;
  width: 40vw;
  margin-left: 10%;
  border-radius: 7%;
}
#contact_bg_content {
  font-size: 25px;
  width: 50%;
}
@media screen and (max-width: 860px) {
  #contactformContact {
    margin: 0;
    padding: 0;
    position: relative;
    left: -12vw;
    width: 98vw;
  }
  #IMG {
    float: left;
    width: 55vw;
  }
  #contact_bg_content {
    width: 63vw;
  }
}
</style>
