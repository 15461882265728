<template>
  <div id="SolutionContainer">
    <!-- topics -->
    <div id="left">
      <p>{{ $t("Solution[0]") }}</p>
      <b style="font-size: 40px; font-weight: 900">{{ $t("Solution[1]") }}</b>
      <br />
      <br />
      <div id="hiddenSolutions">
        <button
          @click="show('content1')"
          style="border-bottom: solid #b4b4b4 1px"
        >
          {{ $t("Solution[2][0]") }}
        </button>
        <button
          @click="show('content2')"
          style="border-bottom: solid #b4b4b4 1px"
        >
          {{ $t("Solution[2][1]") }}
        </button>
        <button
          @click="show('content3')"
          style="border-bottom: solid #b4b4b4 1px"
        >
          {{ $t("Solution[2][2]") }}
        </button>
        <button
          @click="show('content4')"
          style="border-bottom: solid #b4b4b4 1px"
        >
          {{ $t("Solution[2][3]") }}
        </button>
        <button
          @click="show('content5')"
          style="border-bottom: solid #b4b4b4 1px"
        >
          {{ $t("Solution[2][4]") }}
        </button>
        <button
          @click="show('content6')"
          style="border-bottom: solid #b4b4b4 1px"
        >
          {{ $t("Solution[2][5]") }}
        </button>
        <button
          @click="show('content7')"
          style="border-bottom: solid #b4b4b4 1px"
        >
          {{ $t("Solution[2][6]") }}
        </button>
        <button @click="show('content8')">{{ $t("Solution[2][7]") }}</button>
      </div>
      <table id="shownSolutions">
        <tr>
          <td
            @click="show('content1')"
            style="border-bottom: solid #b4b4b4 1px; padding: 20px"
            :class="{ active: activeButton === 'content1' }"
          >
            {{ $t("Solution[2][0]") }}
          </td>
        </tr>
        <tr>
          <td
            @click="show('content2')"
            style="border-bottom: solid #b4b4b4 1px; padding: 20px"
            :class="{ active: activeButton === 'content2' }"
          >
            {{ $t("Solution[2][1]") }}
          </td>
        </tr>
        <tr>
          <td
            @click="show('content3')"
            style="border-bottom: solid #b4b4b4 1px; padding: 20px"
            :class="{ active: activeButton === 'content3' }"
          >
            {{ $t("Solution[2][2]") }}
          </td>
        </tr>
        <tr>
          <td
            @click="show('content4')"
            style="border-bottom: solid #b4b4b4 1px; padding: 20px"
            :class="{ active: activeButton === 'content4' }"
          >
            {{ $t("Solution[2][3]") }}
          </td>
        </tr>
        <tr>
          <td
            @click="show('content5')"
            style="border-bottom: solid #b4b4b4 1px; padding: 20px"
            :class="{ active: activeButton === 'content5' }"
          >
            {{ $t("Solution[2][4]") }}
          </td>
        </tr>
        <tr>
          <td
            @click="show('content6')"
            style="border-bottom: solid #b4b4b4 1px; padding: 20px"
            :class="{ active: activeButton === 'content6' }"
          >
            {{ $t("Solution[2][5]") }}
          </td>
        </tr>
        <tr>
          <td
            @click="show('content7')"
            style="border-bottom: solid #b4b4b4 1px; padding: 20px"
            :class="{ active: activeButton === 'content7' }"
          >
            {{ $t("Solution[2][6]") }}
          </td>
        </tr>
        <tr>
          <td
            @click="show('content8')"
            style="padding: 20px"
            :class="{ active: activeButton === 'content8' }"
          >
            {{ $t("Solution[2][7]") }}
          </td>
        </tr>
      </table>
    </div>
    <!-- details -->
    <div id="right">
      <!-- 1 -->
      <div id="content1" class="contentk first">
        <img src="../img/NLC new website/oursolution1.png" alt="" />
        <br />
        <b style="font-size: 30px">{{ $t("Solution[2][0]") }}</b>
        <p style="font-size: 25px">
          {{ $t("Solution[3][0]") }}
        </p>
        <button>
          <a
            href="https://www.vividkidsblog.page/products"
            style="
              color: white;
              padding-left: 10px;
              padding-right: 10px;
              font-weight: 600;
            "
          >
            {{ $t("Solution[4]") }}</a
          >
        </button>
      </div>
      <!-- 2 -->
      <div id="content2" class="contentk">
        <img src="../img/NLC new website/oursolution2.png" alt="" />
        <br />
        <b style="font-size: 30px">{{ $t("Solution[2][1]") }}</b>
        <p style="font-size: 25px">
          {{ $t("Solution[3][1]") }}
        </p>
        <button>
          <a
            href="https://www.vividkidsblog.page/products"
            style="
              color: white;
              padding-left: 10px;
              padding-right: 10px;
              font-weight: 600;
            "
            >{{ $t("Solution[4]") }}</a
          >
        </button>
      </div>
      <!-- 3 -->
      <div id="content3" class="contentk">
        <img src="../img/NLC new website/oursolution3.png" alt="" />
        <br />
        <b style="font-size: 30px">{{ $t("Solution[2][2]") }}</b>
        <p style="font-size: 25px">
          {{ $t("Solution[3][2]") }}
        </p>
        <button>
          <a
            href="https://www.vividkidsblog.page/products"
            style="
              color: white;
              padding-left: 10px;
              padding-right: 10px;
              font-weight: 600;
            "
            >{{ $t("Solution[4]") }}</a
          >
        </button>
      </div>
      <!-- 4 -->
      <div id="content4" class="contentk">
        <img src="../img/NLC new website/oursolution4.png" alt="" />
        <br />
        <b style="font-size: 30px">{{ $t("Solution[2][3]") }}</b>
        <p style="font-size: 25px">
          {{ $t("Solution[3][3]") }}
        </p>
        <button>
          <a
            href="https://www.vividkidsblog.page/products"
            style="
              color: white;
              padding-left: 10px;
              padding-right: 10px;
              font-weight: 600;
            "
            >{{ $t("Solution[4]") }}</a
          >
        </button>
      </div>
      <!-- 5 -->
      <div id="content5" class="contentk">
        <img src="../img/NLC new website/oursolution5.png" alt="" />
        <br />
        <b style="font-size: 30px">{{ $t("Solution[2][4]") }}</b>
        <p style="font-size: 25px">
          {{ $t("Solution[3][4]") }}
        </p>
        <button>
          <a
            href="https://www.vividkidsblog.page/products"
            style="
              color: white;
              padding-left: 10px;
              padding-right: 10px;
              font-weight: 600;
            "
            >{{ $t("Solution[4]") }}</a
          >
        </button>
      </div>
      <!-- 6 -->
      <div id="content6" class="contentk">
        <img src="../img/NLC new website/oursolution6.png" alt="" />
        <br />
        <b style="font-size: 30px">{{ $t("Solution[2][5]") }}</b>
        <p style="font-size: 25px">
          {{ $t("Solution[3][5]") }}
        </p>
        <button>
          <a
            href="https://www.vividkidsblog.page/products"
            style="
              color: white;
              padding-left: 10px;
              padding-right: 10px;
              font-weight: 600;
            "
            >{{ $t("Solution[4]") }}</a
          >
        </button>
      </div>
      <!-- 7 -->
      <div id="content7" class="contentk">
        <img src="../img/NLC new website/oursolution7.png" alt="" />
        <b style="font-size: 30px">{{ $t("Solution[2][6]") }}</b>
        <p style="font-size: 25px">
          {{ $t("Solution[3][6]") }}
        </p>
        <button>
          <a
            href="https://www.vividkidsblog.page/products"
            style="
              color: white;
              padding-left: 10px;
              padding-right: 10px;
              font-weight: 600;
            "
            >L{{ $t("Solution[4]") }}</a
          >
        </button>
      </div>
      <!-- 8 -->
      <div id="content8" class="contentk">
        <img src="../img/NLC new website/oursolution8.png" alt="" />
        <br />
        <b style="font-size: 30px">{{ $t("Solution[2][7]") }}</b>
        <p style="font-size: 25px">
          {{ $t("Solution[3][7]") }}
        </p>
        <button>
          <a
            href="https://www.vividkidsblog.page/products"
            style="
              color: white;
              padding-left: 10px;
              padding-right: 10px;
              font-weight: 600;
            "
            >{{ $t("Solution[4]") }}</a
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeButton: null,
    };
  },
  mounted() {
    this.show("content1");
  },
  methods: {
    // hide all then display selected
    show(contentId) {
      const contents = Array.from(document.getElementsByClassName("contentk"));
      contents.forEach((content) => (content.style.display = "none"));
      document.getElementById(contentId).style.display = "block";
      this.activeButton = contentId;
    },
  },
};
</script>

<style scoped>
#SolutionContainer {
  scroll-margin-top: 150px;
  width: 80%;
  position: relative;
  left: 6%;
  display: flex;
}
#left {
  width: 45%;
  padding-right: 10%;
}
#right {
  width: 45%;
}

table {
  border-collapse: collapse;
}

td {
  font-size: 30px;
  border: none;
  font-weight: 600;
}

.contentk {
  display: none;
}
.contentk img {
  width: 35vw;
  height: auto;
}
.first {
  display: block;
}
button {
  padding: 10px 2px;
  border: none;
  border-radius: 5px;
  background-color: #f7901e;
  color: white;
}
#shownSolutions td.active {
  color: #dc6803;
}
#hiddenSolutions button {
  margin: 5px;
  padding: 1px;
}
#hiddenSolutions {
  display: none;
}
#shownSolutions {
  display: block;
}
@media screen and (max-width: 860px) {
  #SolutionContainer {
    display: block;
  }
  #left {
    width: 90vw;
  }
  #right {
    width: 90vw;
  }
  #right img {
    width: 90vw;
  }
  #hiddenSolutions {
    display: block;
  }
  #shownSolutions {
    display: none;
  }
}
</style>
