<template>
  <div>
    <img
      src="../img/NLC new website/vector.png"
      alt="vector-img"
      id="vector"
      style="
        width: 7%;
        height: fit-content;
        position: absolute;
        left: 0;
        top: 10%;
      "
    />
    <p style="color: #dc6803; padding-top: 5%">
      {{ $t("Technology['header']") }}
    </p>
    <h1>{{ $t("Technology['title']") }}</h1>
    <br />
    <p style="font-size: 25px">
      {{ $t("Technology['intro']") }}
    </p>
    <br /><br />
    <h1>{{ $t("Technology['subtitle']") }}</h1>
    <br /><br />
    <div>
      <img src="../img/2ndOnLandingPage.jpeg" alt="" id="IMG" />
      <table>
        <tbody>
          <tr>
            <td>
              <img
                src="../img/NLC new website/tech1.png"
                alt=""
                style="width: 50px"
              />
            </td>
            <td>
              <h3>{{ $t("Technology['tech'][0]['title']") }}</h3>
              <p>
                {{ $t("Technology['tech'][0]['body']") }}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <img
                src="../img/NLC new website/tech2.png"
                alt=""
                style="width: 50px"
              />
            </td>
            <td>
              <h3>{{ $t("Technology['tech'][1]['title']") }}</h3>
              <p>
                {{ $t("Technology['tech'][1]['body']") }}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <img
                src="../img/NLC new website/tech3.png"
                alt=""
                style="width: 50px"
              />
            </td>
            <td>
              <h3>{{ $t("Technology['tech'][2]['title']") }}</h3>
              <p>
                {{ $t("Technology['tech'][2]['body']") }}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <img
                src="../img/NLC new website/tech4.png"
                alt=""
                style="width: 50px"
              />
            </td>
            <td>
              <h3>{{ $t("Technology['tech'][3]['title']") }}</h3>
              <p>
                {{ $t("Technology['tech'][3]['body']") }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button
      style="
        background-color: #dc6803;
        color: white;
        padding: 15px;
        border: none;
      "
    >
      <a href="/#SolutionContainer" style="color: white">{{
        $t("Technology['solution']")
      }}</a>
    </button>

    <br /><br /><br />
    <div id="tech_bg">
      <h1 style="color: #831b19">{{ $t("Technology['whyMatter'][0]") }}</h1>
      <br />
      <table id="hiddenWhyItMatters">
        <tr>
          <td>
            <b>{{ $t("Technology['whyMatter'][1][0]") }}</b>
            <p>
              {{ $t("Technology['whyMatter'][1][1]") }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <b>{{ $t("Technology['whyMatter'][2][0]") }}</b>
            <p>
              {{ $t("Technology['whyMatter'][2][1]") }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <b>{{ $t("Technology['whyMatter'][3][0]") }}</b>
            <p>
              {{ $t("Technology['whyMatter'][3][1]") }}
            </p>
          </td>
        </tr>
      </table>
      <table id="TABLE">
        <tbody>
          <tr>
            <td>
              <b>{{ $t("Technology['whyMatter'][1][0]") }}</b>
            </td>
            <td>
              <b>{{ $t("Technology['whyMatter'][2][0]") }}</b>
            </td>
            <td>
              <b>{{ $t("Technology['whyMatter'][3][0]") }}</b>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                {{ $t("Technology['whyMatter'][1][1]") }}
              </p>
            </td>
            <td>
              <p>
                {{ $t("Technology['whyMatter'][2][1]") }}
              </p>
            </td>
            <td>
              <p>
                {{ $t("Technology['whyMatter'][3][1]") }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  mounted: function () {
    this.goTop();
  },
  methods: {
    goTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
td {
  border: none;
  vertical-align: top;
}
#tech_bg td {
  width: 33.33%;
}
b {
  font-size: 25px;
}

#tech_bg {
  background-image: url("../img/NLC new website/tech_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5%;
}
#IMG {
  float: right;
  width: 50%;
  border-radius: 50px;
}
#hiddenWhyItMatters {
  display: none;
}
@media screen and (max-width: 860px) {
  #IMG {
    width: 75vw;
  }
  #tech_bg {
    width: 95vw;
    margin-left: -10vw;
  }
  #hiddenWhyItMatters {
    display: block;
  }
  #TABLE {
    display: none;
  }
}
</style>
