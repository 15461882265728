<template>
  <div>
    <div id="register" class="register">
      <h1>{{ $t("authentication.registertitle") }}</h1>
      <div class="container_register">
        <!-- content mid: register form -->
        <div class="container_mid">
          <div id="registerforms">
            <h1 class="brand">
              <span>{{ $t("newsletterform.neuro") }}</span>
              {{ $t("newsletterform.leap") }}
            </h1>
            <div class="register_wrapper">
              <div class="registerform">
                <div class="alert">
                  You have successfully submitted registeration request! We will
                  notify your request result after review!
                </div>
                <form id="registerform" target="targetIfr">
                  <!-- username -->
                  <p>
                    <label for="username"
                      >{{ $t("authentication.username") }}*</label
                    >
                    <input
                      id="username"
                      v-model="input.username"
                      name="username"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !username }"
                      placeholder="Username"
                      required
                    />
                  </p>
                  <span id="s_username"></span>

                  <!-- email -->
                  <p>
                    <label for="email">{{ $t("authentication.email") }}*</label>
                    <input
                      id="email"
                      v-model="input.email"
                      name="email"
                      type="email"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !email }"
                      placeholder="Email"
                      required
                    />
                  </p>
                  <span id="s_email"></span>

                  <!-- firstname -->
                  <p>
                    <label for="firstname"
                      >{{ $t("authentication.firstname") }}*</label
                    >
                    <input
                      id="firstname"
                      v-model="input.firstname"
                      name="firstname"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !firstname }"
                      placeholder="First Name"
                      required
                    />
                  </p>
                  <span id="s_firstname"></span>

                  <!-- middle initial -->
                  <p>
                    <label for="middleinitial">{{
                      $t("authentication.middleinitial")
                    }}</label>
                    <input
                      id="middleinitial"
                      v-model="input.middleinitial"
                      name="middleinitial"
                      type="text"
                      maxlength="1"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !middleinitial }"
                      placeholder="Middle Initial"
                    />
                  </p>

                  <!-- last name -->
                  <p>
                    <label for="lastname"
                      >{{ $t("authentication.lastname") }}*</label
                    >
                    <input
                      id="lastname"
                      v-model="input.lastname"
                      name="lastname"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !lastname }"
                      placeholder="Last Name"
                      required
                    />
                  </p>
                  <span id="s_lastname"></span>

                  <!-- preferred name -->
                  <p>
                    <label for="preferredname"
                      >{{ $t("authentication.preferredname") }}:
                    </label>
                    <input
                      id="preferredname"
                      v-model="input.preferredname"
                      name="preferredname"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !preferredname }"
                      placeholder="Preferred Name"
                    />
                  </p>

                  <!-- password -->
                  <p>
                    <label for="password"
                      >{{ $t("authentication.password") }}*</label
                    >
                    <input
                      id="password"
                      v-model="input.password"
                      name="password"
                      type="password"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !password }"
                      placeholder="Password"
                      required
                    />
                  </p>
                  <span id="s_pw"></span>

                  <!-- role -->
                  <p>
                    <label for="Role/Designation"
                      >{{ $t("authentication.role") }}
                    </label>
                    <select
                      v-model="input.role_designation"
                      name="interestgroup"
                      id="interestgroup"
                    >
                      <option>{{ $t("contactform.role_parent") }}</option>
                      <option>{{ $t("contactform.role_investor") }}</option>
                      <option>{{ $t("contactform.role_volunteer") }}</option>
                      <option>{{ $t("contactform.role_supporter") }}</option>
                      <option>{{ $t("contactform.role_others") }}</option>
                    </select>
                  </p>

                  <!-- city -->
                  <p>
                    <label for="city">{{ $t("authentication.city") }}*</label>
                    <input
                      id="city"
                      v-model="input.city"
                      name="city"
                      type="text"
                      class="form-control"
                      :class="{ 'is-valid': submitted && !city }"
                      placeholder="City"
                      required
                    />
                  </p>
                  <span id="s_city"></span>

                  <!-- state -->
                  <p>
                    <label for="state">{{ $t("authentication.state") }}*</label>
                    <input
                      id="state"
                      v-model="input.state"
                      name="state"
                      type="text"
                      class="form-control"
                      :class="{ 'is-valid': submitted && !state }"
                      placeholder="State"
                      required
                    />
                  </p>
                  <span id="s_state"></span>

                  <!-- country -->
                  <p>
                    <label for="country"
                      >{{ $t("authentication.country") }}*</label
                    >
                    <input
                      id="country"
                      v-model="input.country"
                      name="country"
                      type="text"
                      class="form-control"
                      :class="{ 'is-valid': submitted && !country }"
                      placeholder="Country"
                      required
                    />
                  </p>
                  <span id="s_country"></span>

                  <!-- zipcode -->
                  <p>
                    <label for="zipcode">{{
                      $t("authentication.zipcode")
                    }}</label>
                    <input
                      id="zipcode"
                      v-model="input.zipcode"
                      name="zipcode"
                      type="text"
                      class="form-control"
                      :class="{ 'is-valid': submitted && !zipcode }"
                      placeholder="zipcode"
                    />
                  </p>

                  <!-- job function -->
                  <p>
                    <label for="jobFunction">
                      {{ $t("authentication.jobfunction") }}
                    </label>
                    <select
                      name="jobFunction"
                      id="jobFunction"
                      v-model="input.jobFunction"
                    >
                      <option value="Accounting">Accounting</option>
                      <option value="Admin & HR, Banking / Finance">
                        Admin & HR, Banking / Finance
                      </option>
                      <option value="Beauty Care / Health">
                        Beauty Care / Health
                      </option>
                      <option value="Building & Construction">
                        Building & Construction
                      </option>
                      <option value="Design">Design</option>
                      <option value="E-Commerce">E-Commerce</option>
                      <option value="Education">Education</option>
                      <option value="Hospitality / F&B">
                        Hospitality / F&B
                      </option>
                      <option value="Information Technology">
                        Information Technology
                      </option>
                      <option value="Insurance">Insurance</option>
                      <option value="Management">Management</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Marketing / Public Relations">
                        Marketing / Public Relations
                      </option>
                      <option value="Media / Advertising">
                        Media / Advertising
                      </option>
                      <option value="Medical Servcie">Medical Servcie</option>
                      <option value="Merchandising & Purchasing">
                        Merchandising & Purchasing
                      </option>
                      <option value="Professional Service">
                        Professional Service
                      </option>
                      <option value="Property / Rental Estate">
                        Property / Rental Estate
                      </option>
                      <option value="Public Sector / Civil Service">
                        Public Sector / Civil Service
                      </option>
                      <option value="Sales, CS & Business Development">
                        Sales, CS & Business Development
                      </option>
                      <option value="Science / R&D">Science / R&D</option>
                      <option value="Transportation & Logistics">
                        Transportation & Logistics
                      </option>
                      <option value="A self-employed Specialist">
                        A self-employed Specialist
                      </option>
                      <option value="A Specialist working for an organization">
                        A Specialist working for an organization
                      </option>
                      <option value="Parent/Care-taker of a Child">
                        Parent/Care-taker of a Child
                      </option>
                      <option value="Primary Care Physician">
                        Primary Care Physician
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </p>

                  <!-- job title -->
                  <p>
                    <label for="jobTitle">
                      {{ $t("authentication.jobtitle") }}
                    </label>
                    <input type="text" v-model="input.jobTitle" />
                  </p>

                  <!-- job level -->
                  <p>
                    <label for="jobLevel">
                      {{ $t("authentication.joblevel") }}
                    </label>
                    <select
                      name="jobLevel"
                      id="jobLevel"
                      required
                      v-model="input.jobLevel"
                    >
                      <option value="Executive or senior management(C-suite)">
                        Executive or senior management(C-suite)
                      </option>
                      <option
                        value="Middle management(director, general manager)"
                      >
                        Middle management(director, general manager)
                      </option>
                      <option
                        value="First-level management(assistant manager, manager, senior manager)"
                      >
                        First-level management(assistant manager, manager,
                        senior manager)
                      </option>
                      <option value="Intermediate or experienced(senior staff)">
                        Intermediate or experienced(senior staff)
                      </option>
                      <option value="Entry-level">Entry-level</option>
                      <option value="Private Party">Private Party</option>
                    </select>
                  </p>

                  <!--  register button -->
                  <p>
                    <button @click="check($event)">
                      {{ $t("authentication.registertitle") }}
                    </button>
                  </p>

                  <!-- back home page -->
                  <p>
                    <router-link to="/" class="btn btn-link">
                      <button>{{ $t("authentication.gohome") }}</button>
                    </router-link>
                  </p>

                  <!-- go back to login button -->
                  <p>
                    <router-link to="/login" class="btn btn-link">
                      <button type="button">
                        {{ $t("authentication.gologin") }}
                      </button>
                    </router-link>
                  </p>
                </form>
                <iframe name="targetIfr" style="display: none"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import instance from "../axios";
export default {
  name: "Register",
  data() {
    return {
      input: {
        username: "",
        email: "",
        firstname: "",
        middleinitial: "",
        lastname: "",
        preferredname: "",
        password: "",
        role_designation: "",
        country: "",
        state: "",
        city: "",
        zipcode: "",
        jobFunction: "",
        jobLevel: "",
        jobTitle: "",
      },
      submitted: false,
    };
  },
  methods: {
    check(event) {
      // if any required block is empty, prevent submitting form

      var flag_submit =
        checkUsername() &&
        checkEmail() &&
        checkFirstname() &&
        checkLastname() &&
        checkPassword() &&
        checkCity() &&
        checkState() &&
        checkCountry();
      if (!flag_submit) {
        event.preventDefault();
        return;
      }

      document.getElementById("username").onblur = checkUsername;
      document.getElementById("password").onblur = checkPassword;
      document.getElementById("firstname").onblur = checkFirstname;
      document.getElementById("lastname").onblur = checkLastname;
      document.getElementById("email").onblur = checkEmail;
      document.getElementById("city").onblur = checkCity;
      document.getElementById("state").onblur = checkState;
      document.getElementById("country").onblur = checkCountry;
      this.registerUser();

      // check if username is valid
      function checkUsername() {
        var username = document.getElementById("username").value;
        // USERNAME STANDARD
        var reg_username = /^\w{6,12}$/;
        var flag = reg_username.test(username);
        var s_username = document.getElementById("s_username");
        if (flag) {
          s_username.innerHTML = "valid username";
        } else {
          s_username.innerHTML =
            "Warning: invalid username--need 6-12 number/letter without space";
        }
        return flag;
      }

      // check if email is valid
      function checkEmail() {
        var email = document.getElementById("email").value;
        // EMAIL STANDARD
        var reg_email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        var flag = reg_email.test(email);
        var s_email = document.getElementById("s_email");
        if (flag) {
          s_email.innerHTML = "valid email";
        } else {
          s_email.innerHTML = "Warning: invalid email";
        }
        return flag;
      }

      // check if password is valid
      function checkPassword() {
        var pw = document.getElementById("password").value;
        // PASSWORD STANDARD
        var reg_pw = /^\w{6,12}$/;
        var flag = reg_pw.test(pw);
        var s_pw = document.getElementById("s_pw");
        if (flag) {
          s_pw.innerHTML = "valid password";
        } else {
          s_pw.innerHTML =
            "Warning: invalid password--need 6-12 number/letter without space";
        }
        return flag;
      }

      // check if Firstname is valid
      function checkFirstname() {
        var firstname = document.getElementById("firstname").value;
        // FIRSTNAME STANDARD
        var reg_firstname = /^$/;
        var flag = !reg_firstname.test(firstname);
        var s_firstname = document.getElementById("s_firstname");
        if (flag) {
          s_firstname.innerHTML = "valid firstname";
        } else {
          s_firstname.innerHTML =
            "Warning: invalid firstname--need at least one character";
        }
        return flag;
      }

      // check if lastname is valid
      function checkLastname() {
        var lastname = document.getElementById("lastname").value;
        // LASTNAME STANDARD
        var reg_lastname = /^$/;
        var flag = !reg_lastname.test(lastname);
        var s_lastname = document.getElementById("s_lastname");
        if (flag) {
          s_lastname.innerHTML = "valid lastname";
        } else {
          s_lastname.innerHTML =
            "Warning: invalid lastname--need at least one character";
        }
        return flag;
      }

      // check if city is valid
      function checkCity() {
        var city = document.getElementById("city").value;
        // CITY STANDARD
        var reg_city = /^$/;
        var flag = !reg_city.test(city);
        var s_city = document.getElementById("s_city");
        if (flag) {
          s_city.innerHTML = "valid city";
        } else {
          s_city.innerHTML =
            "Warning: invalid city--need at least one character";
        }
        return flag;
      }

      // check if state is valid
      function checkState() {
        var state = document.getElementById("state").value;
        // STATE STANDARD
        var reg_state = /^$/;
        var flag = !reg_state.test(state);
        var s_state = document.getElementById("s_state");
        if (flag) {
          s_state.innerHTML = "valid state";
        } else {
          s_state.innerHTML =
            "Warning: invalid state--need at least one character";
        }
        return flag;
      }

      // check if country is valid
      function checkCountry() {
        var country = document.getElementById("country").value;
        // COUNTRY STANDARD
        var reg_country = /^$/;
        var flag = !reg_country.test(country);
        var s_country = document.getElementById("s_country");
        if (flag) {
          s_country.innerHTML = "valid country";
        } else {
          s_country.innerHTML =
            "Warning: invalid country--need at least one character";
        }
        return flag;
      }
    },
    registerUser() {
      // console.log("register");
      const data = {
        username: this.input.username,
        email: this.input.email,
        firstname: this.input.firstname,
        middleinitial: this.input.middleinitial,
        lastname: this.input.lastname,
        preferredname: this.input.preferredname,
        password: this.input.password,
        role: this.input.role_designation,
        country: this.input.country,
        state: this.input.state,
        city: this.input.city,
        zipcode: this.input.zipcode,
        jobfunction: this.input.jobFunction,
        joblevel: this.input.jobLevel,
        jobtitle: this.input.jobTitle,
      };
      instance
        .post("api/register", data)
        .then(function () {
          // console.log(response);
          alert(
            "You have successfully submitted registeration request! We will notify your request result after review!"
          );
        })
        .catch(function () {
          // console.log(error);
          alert(
            "We encounter error when creating an account for you, please try different username or email to register."
          );
        });
    },
  },
};
</script>

<style scoped>
.container_register {
  background-color: #f4892f;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

h2 {
  margin: 0;
}

a {
  color: black;
}

.mail_crypt {
  unicode-bidi: bidi-override;
  direction: rtl;
}

/* registerform */
* {
  box-sizing: border-box;
}

body {
  background: #f9c75c;
  color: #f4892f;
  line-height: 1.6;
  padding: 1em;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.register_wrapper {
  box-shadow: 0 0 20px 0 rgba(243, 142, 19, 0.7);
}

.register_wrapper > * {
  padding: 1em;
}

.registerform {
  background: #f9c75c;
}

/* FORM STYLES */
.container_mid form {
  display: grid;
  grid-gap: 10px;
}

.container_mid form label {
  display: block;
}

.container_mid form p {
  margin: 0;
}

.container_mid form .full {
  grid-column: 1 / 3;
}

.container_mid form button,
.container_mid form input,
.container_mid form textarea,
.container_mid form select,
.container_mid form option {
  width: 100%;
  padding: 1em;
  border: 1px solid #faa535;
}

.container_mid form button {
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.container_mid form button:hover,
.container_mid form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
  .register_wrapper {
    display: grid;
  }

  .register_wrapper > * {
    padding: 2em;
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
}

@media screen and (max-width: 601px) {
  .container_register_wrapper {
    display: block;
  }
}
</style>
