<template>
  <div id="fundraising">
    <br /><br /><br />
    <b-container>
      <b-row class id="headerSupport">
        <!-- donate button -->
        <b-col class="columnButton" sm md lg>
          <router-link to="/donate">
            <div class="imagebutton">
              <img class="placeholder" src="../img/donate.png" alt="donate" />
              <span class="butHeading"> {{ $t("donate.heading") }} </span>
            </div>
          </router-link>
        </b-col>
        <!-- volunteer button -->
        <b-col class="columnButton" sm md lg>
          <router-link to="/volunteer">
            <div class="imagebutton">
              <img
                class="placeholder"
                src="../img/volunteer.png"
                alt="volunteer"
              />
              <span class="butHeading"> {{ $t("volunteer.heading") }}</span>
            </div>
          </router-link>
        </b-col>
        <!-- refer customer button -->
        <b-col class="columnButton" sm md lg>
          <router-link to="/refercustomer">
            <div class="imagebutton">
              <img
                class="placeholder"
                src="../img/referCustomerCollaborator.png"
                alt="referCustomerCollaborator"
              />
              <span class="butHeading"> {{ $t("referCustomer.heading") }}</span>
            </div>
          </router-link>
        </b-col>
        <!-- equity button -->
        <b-col class="columnButton" sm md lg>
          <div class="imagebutton">
            <img class="placeholder" src="../img/equity.png" alt="equity" />
            <span class="butHeading"> {{ $t("equityInvest.heading") }}</span>
            <br /><br /><span>{{ $t("directInvest.not_available") }}</span>
          </div>
        </b-col>
        <!-- <b-col class="columnButton" sm md lg>
          <a href="/equityinvest">
            <div class="imagebutton">
              <img class="placeholder" src="../img/equity.png" alt="equity" />
              <span class="butHeading"> {{ $t("equityInvest.heading") }}</span>
            </div>
          </a>
        </b-col> -->
        <!-- direct invest button -->
        <b-col class="columnButton" sm md lg>
          <div class="imagebutton">
            <img class="placeholder" src="../img/direct.png" alt="direct" />
            <span class="butHeading">{{ $t("directInvest.heading") }}</span>
            <br /><br /><span>{{ $t("directInvest.not_available") }}</span>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <ul>
      <!-- start section -->
      <h1>{{ $t("faq_investing.heading") }}</h1>
      <h2>{{ $t("faq_investing.getting_started") }}</h2>
      <!-- faq 1 -->
      <button class="collapsible">
        {{ $t("faq_investing.question_1") }}
      </button>
      <div class="collapsible_content">
        <p>{{ $t("faq_investing.answer_1") }}</p>
      </div>
      <!-- faq 2 -->
      <button class="collapsible">
        {{ $t("faq_investing.question_2") }}
      </button>
      <div class="collapsible_content">
        <p>{{ $t("faq_investing.answer_2") }}</p>
      </div>
    </ul>

    <ul>
      <!-- investing section -->
      <h2>{{ $t("faq_investing.investing") }}</h2>
      <!-- faq 3 -->
      <button class="collapsible">
        {{ $t("faq_investing.question_3") }}
      </button>
      <div class="collapsible_content">
        <p>{{ $t("faq_investing.answer_3") }}</p>
      </div>
      <!-- faq 4 -->
      <button class="collapsible">
        {{ $t("faq_investing.question_4") }}
      </button>
      <div class="collapsible_content">
        <p>{{ $t("faq_investing.answer_4") }}</p>
      </div>
      <!-- faq 5 -->
      <button class="collapsible">
        {{ $t("faq_investing.question_5") }}
      </button>
      <div class="collapsible_content">
        <p>{{ $t("faq_investing.answer_5") }}</p>
      </div>
    </ul>

    <ul>
      <!-- donate section -->
      <h2>{{ $t("donate.heading") }}</h2>
      <!-- faq 6 -->
      <button class="collapsible">
        {{ $t("faq_investing.question_6") }}
      </button>
      <div class="collapsible_content">
        <p>{{ $t("faq_investing.answer_6") }}</p>
      </div>
      <!-- faq 7 -->
      <button class="collapsible">
        {{ $t("faq_investing.question_7") }}
      </button>
      <div class="collapsible_content">
        <p>{{ $t("faq_investing.answer_7") }}</p>
      </div>
    </ul>

    <ul>
      <!-- volunteer section -->
      <h2>{{ $t("faq_investing.volunteering") }}</h2>
      <!-- faq 8 -->
      <button class="collapsible">
        {{ $t("faq_investing.question_8") }}
      </button>
      <div class="collapsible_content">
        <p>{{ $t("faq_investing.answer_8") }}</p>
      </div>
      <!-- faq 9 -->
      <button class="collapsible">
        {{ $t("faq_investing.question_9") }}
      </button>
      <div class="collapsible_content">
        <p>{{ $t("faq_investing.answer_9") }}</p>
      </div>
      <!-- faq 10 -->
      <button class="collapsible">
        {{ $t("faq_investing.question_10") }}
      </button>
      <div class="collapsible_content">
        <p>{{ $t("faq_investing.answer_10") }}</p>
      </div>
      <!-- faq 11 -->
      <button class="collapsible">
        {{ $t("faq_investing.question_11") }}
      </button>
      <div class="collapsible_content">
        <p>{{ $t("faq_investing.answer_11") }}</p>
      </div>
    </ul>

    <!-- Add Components to Page
    <Donate />
    <hr>
    <Volunteer />
    <hr>
    <ReferCustomer />
    <hr>
    <EquityInvest />
    <hr>
    <DirectInvest /> -->
  </div>
</template>

<script>
//TESTING API
import dataService from "../services/dataService";

//Import Components which are used on Fundraising page
// import investing_faq from "./investing_faq";

export default {
  name: "SupportUs",
  mounted: function () {
    this.goTop();
  },
  methods: {
    //Testing the API
    testAPI: function () {
      dataService.getUsers();
    },
    goTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.collapsible {
  font-family: "Manrope", sans-serif;
}
.headerSupport {
  margin-bottom: 20px;
}

.buttons {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: flex;
  padding: 0.5em;
}

.imagebutton {
  height: 55px;
  width: 19%;
  background: #f4892f;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  opacity: 0.85;
  margin: 10px;
  padding: 20px 10px;
}

.columnButton {
  margin: 10px;
  padding: 0px;
}

.placeholder {
  display: block;
  width: 40px;
  margin-right: auto;
  margin-left: auto;
}

.butHeading {
  color: #fff;
  padding-bottom: 20px;
}

h2 {
  margin: 10px;
}

/* HOVER STYLES */

.imagebutton:hover {
  opacity: 1;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}
</style>
