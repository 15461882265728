<template>
  <div id="referCustomer">
    <h1>{{ $t("referCustomer.heading") }}</h1>

    <div class="container_referCustomer">
      <div class="container_mid">
        <!-- title -->
        <h1 class="brand">
          <span>{{ $t("referCustomer.neuro") }}</span
          >{{ $t("referCustomer.leap") }}
        </h1>
        <div class="wrapper">
          <div class="referCustomerForm">
            <form
              id="referCustomer"
              ref="formReferCustomer"
              @submit.prevent="submitFormReferCustomer('referCustomer')"
            >
              <b-container>
                <h2>{{ $t("referCustomer.yourinfo") }}</h2>
                <b-row>
                  <!-- firstname -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.firstname") }} *</label>
                    <input
                      id="txt_firstnamereferral"
                      type="text"
                      pattern="[a-zA-Z'-'\s]*"
                      name="txt_firstnamereferral"
                      required
                    />
                  </b-col>
                  <!-- lastname -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.lastname") }} *</label>
                    <input
                      id="txt_lastnamereferral"
                      type="text"
                      pattern="[a-zA-Z'-'\s]*"
                      name="txt_lastnamereferral"
                      required
                    />
                  </b-col>
                  <!-- preferredname -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.preferredname") }}</label>
                    <input
                      id="txt_preferrednamereferral"
                      type="text"
                      name="txt_preferrednamereferral"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- email -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.email") }} *</label>
                    <input
                      id="email_address_referral"
                      type="email"
                      name="email_address_referral"
                      @keyup="input_check_email('email_address_referral')"
                      required
                    />
                  </b-col>
                  <!-- phone number -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.phone") }}</label>
                    <input
                      id="nr_phonereferral"
                      type="number"
                      name="nr_phonereferral"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <!-- city -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.city") }} </label>
                    <input id="txt_city" type="text" name="txt_city" />
                  </b-col>
                  <!-- state -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.state") }} </label>
                    <input id="txt_state" type="text" name="txt_state" />
                  </b-col>
                </b-row>

                <b-row>
                  <!-- country -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.country") }} *</label>
                    <template>
                      <b-form-select
                        id="drpdwn_country"
                        name="drpdwn_country"
                        v-model="selected_referral"
                        :options="getCountries()"
                        required
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled>{{
                            $t("countries.250")
                          }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </template>
                  </b-col>
                  <!-- zipcode -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.zipcode") }} </label>
                    <input id="txt_zipcode" type="text" name="txt_zipcode" />
                  </b-col>
                </b-row>

                <b-row>
                  <!-- pocc title -->
                  <b-col sm>
                    <label class="pocc_title">{{
                      $t("referCustomer.information_pocc")
                    }}</label>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- pocc firstname -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.firstname_pocc") }} *</label>
                    <input
                      id="txt_firstnamepocc"
                      type="text"
                      name="txt_firstnamepocc"
                      pattern="[a-zA-Z'-'\s]*"
                      required
                    />
                  </b-col>
                  <!-- pocc lastname -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.lastname_pocc") }} *</label>
                    <input
                      id="txt_lastnamepocc"
                      type="text"
                      name="txt_lastnamepocc"
                      pattern="[a-zA-Z'-'\s]*"
                      required
                    />
                  </b-col>
                  <!-- pocc preferredname -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.preferredname") }}</label>
                    <input
                      id="txt_preferrednamepocc"
                      type="text"
                      name="txt_preferrednamepocc"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- pocc email -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.email_pocc") }} *</label>
                    <input
                      id="email_address_pocc"
                      type="email"
                      name="email_address_pocc"
                      @keyup="input_check_email('email_address_pocc')"
                      required
                    />
                  </b-col>
                  <!-- pocc phone number -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.phone_pocc") }}</label>
                    <input
                      id="nr_phonepocc"
                      type="number"
                      name="nr_phonepocc"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <!-- zipcode -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.zipcode") }} *</label>
                    <input
                      id="txt_zipcodepocc"
                      type="text"
                      name="txt_zipcodepocc"
                      required
                    />
                  </b-col>
                  <!-- city -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.city") }} *</label>
                    <input
                      id="txt_citypocc"
                      type="text"
                      name="txt_citypocc"
                      required
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <!-- state -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.state") }} *</label>
                    <input
                      id="txt_statepocc"
                      type="text"
                      name="txt_statepocc"
                      required
                    />
                  </b-col>
                  <!-- country -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.country") }} *</label>
                    <template>
                      <b-form-select
                        id="drpdwn_countrypocc"
                        name="drpdwn_countrypocc"
                        v-model="selected_pocc"
                        :options="getCountries()"
                        required
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled>{{
                            $t("countries.250")
                          }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </template>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- first select -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.customer/collab") }} *</label>
                    <select
                      id="drpdwn_customer_collaborator"
                      name="drpdwn_customer_collaborator"
                      required
                    >
                      <option value="" disabled selected>
                        {{ $t("referCustomer.placeholder") }}
                      </option>
                      <option>{{ $t("referCustomer.customer") }}</option>
                      <option>{{ $t("referCustomer.collaborator") }}</option>
                    </select>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- second select -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.role") }} *</label>
                    <select
                      id="drpdwn_interestGroup"
                      name="drpdwn_interestGroup"
                      required
                    >
                      <option value="" disabled selected>
                        {{ $t("referCustomer.placeholder") }}
                      </option>

                      <option>CEO</option>
                      <option>CTO</option>
                      <option>CFO</option>
                      <option>{{ $t("referCustomer.role_law") }}</option>
                      <option>
                        {{ $t("referCustomer.role_specialist") }}
                      </option>
                      <option>
                        {{ $t("referCustomer.role_healthcare") }}
                      </option>
                      <option>
                        {{ $t("referCustomer.role_representive") }}
                      </option>
                      <option>{{ $t("referCustomer.role_teacher") }}</option>
                      <option>{{ $t("referCustomer.role_parent") }}</option>
                      <option>{{ $t("referCustomer.role_PCP") }}</option>
                      <option>{{ $t("referCustomer.role_press") }}</option>
                      <option>{{ $t("referCustomer.role_investor") }}</option>
                      <option>{{ $t("referCustomer.role_volunteer") }}</option>
                      <option>{{ $t("referCustomer.role_supporter") }}</option>
                      <option>{{ $t("referCustomer.role_others") }}</option>
                    </select>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- comment -->
                  <b-col sm>
                    <label>{{ $t("referCustomer.comments") }}</label>
                    <textarea
                      id="txt_messagereferral"
                      name="txt_messagereferral"
                      rows="3"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- submit button -->
                  <b-col sm>
                    <button
                      type="submit"
                      class="btn_referCustomer"
                      name="submit"
                      value="Submit Now"
                    >
                      {{ $t("referCustomer.btn_submit") }}
                    </button>
                  </b-col>
                  <b-col sm>
                    <button type="reset" class="btn_referCustomer">
                      {{ $t("referCustomer.btn_reset") }}
                    </button>
                  </b-col>
                </b-row>
              </b-container>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div>
      <b-modal id="modal-1" v-model="showReferModal">
        <h2 style="font-family: 'Mandrope', sans-serif">
          Thank you for your submission!
        </h2>
        <br />
        <p style="font-family: 'Mandrope', sans-serif">
          We will get back to you soon.
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import dataService from "../services/dataService";

export default {
  name: "ReferCustomer",
  components: {},
  data() {
    return {
      showReferModal: false,
      selected_referral: null,
      selected_pocc: null,
    };
  },
  methods: {
    // check if email is valid
    input_check_email: function (email_id) {
      var email = document.getElementById(email_id);
      if (
        /([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,})$/.test(email.value) == true
      ) {
        email.setCustomValidity("");
      } else {
        email.setCustomValidity(this.$i18n.t("referCustomer.email_check"));
        email.reportValidity();
      }
    },

    submitFormReferCustomer: function () {
      //Referral
      var firstNameReferral = document.getElementById(
        "txt_firstnamereferral"
      ).value;
      var lastNameReferral = document.getElementById(
        "txt_lastnamereferral"
      ).value;
      var preferredNameReferral = document.getElementById(
        "txt_preferrednamereferral"
      ).value;
      var mailReferral = document.getElementById(
        "email_address_referral"
      ).value;
      var phone_nrRefferal = document.getElementById("nr_phonereferral").value;
      var cityReferral = document.getElementById("txt_city").value;
      var stateReferral = document.getElementById("txt_state").value;
      var inputCountry = document.getElementById("drpdwn_country");
      var countryText = inputCountry.options[inputCountry.selectedIndex].text;
      var zipCode = document.getElementById("txt_zipcode").value;

      //Customer Collaborator
      var firstNameCollaborator =
        document.getElementById("txt_firstnamepocc").value;
      var lastNameCollaborator =
        document.getElementById("txt_lastnamepocc").value;
      var preferredNameCollaborator = document.getElementById(
        "txt_preferrednamepocc"
      ).value;
      var mailCollaborator =
        document.getElementById("email_address_pocc").value;
      var phone_nrCollaborator = document.getElementById("nr_phonepocc").value;
      var cityCollaborator = document.getElementById("txt_citypocc").value;
      var stateCollaborator = document.getElementById("txt_statepocc").value;
      var inputCountryCollaborator =
        document.getElementById("drpdwn_countrypocc");
      var countryTextCollaborator =
        inputCountryCollaborator.options[inputCountryCollaborator.selectedIndex]
          .text;
      var zipCodeCollaborator =
        document.getElementById("txt_zipcodepocc").value;
      var inputCollabOrCustom = document.getElementById(
        "drpdwn_customer_collaborator"
      );
      var cust_collab =
        inputCollabOrCustom.options[inputCollabOrCustom.selectedIndex].text;
      var InputRole = document.getElementById("drpdwn_interestGroup");
      var role = InputRole.options[InputRole.selectedIndex].text;
      var comment = document.getElementById("txt_messagereferral").value;

      var user = {
        email: mailReferral,
        firstname: firstNameReferral,
        lastname: lastNameReferral,
        preferredname: preferredNameReferral,
        phone: phone_nrRefferal,
        city: cityReferral,
        state: stateReferral,
        country: countryText,
        zipCode: zipCode,
      };
      var collaborator = {
        email: mailCollaborator,
        firstname: firstNameCollaborator,
        lastname: lastNameCollaborator,
        preferredname: preferredNameCollaborator,
        phone: phone_nrCollaborator,
        city: cityCollaborator,
        state: stateCollaborator,
        country: countryTextCollaborator,
        zipCode: zipCodeCollaborator,
      };

      dataService.createCustomerCollaborator(
        user,
        collaborator,
        role,
        cust_collab,
        comment
      );
      this.showReferModal = true;
      alert("Submit successful!");
    },

    //get the countries for the country dropdown
    getCountries: function () {
      var countries = [];
      for (let index = 0; index <= 249; index++) {
        countries[index] = this.$i18n.t("countries." + index);
      }
      return countries;
    },
  },
};
</script>

<style scoped>
.container_referCustomer {
  width: 100%;
  background-color: #f4892f;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

.col-sm {
  margin: 0.5em;
}

.container_mid {
  width: 100%;
}

h2 {
  margin: 0;
}

a {
  color: black;
}

.mail_crypt {
  unicode-bidi: bidi-override;
  direction: rtl;
}

/* VolunteerForm */

* {
  box-sizing: border-box;
}

body {
  background: #f9c75c;
  color: #f4892f;
  line-height: 1.6;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.wrapper {
  box-shadow: 0 0 20px 0 rgba(243, 142, 19, 0.7);
}

.wrapper > * {
  padding: 1em;
}

.referCustomerForm {
  background: #f9c75c;
}

/* FORM STYLES */

.container_mid form label {
  display: block;
}

.container_mid form p {
  margin: 0;
}

.container_mid form button,
.container_mid form input,
.container_mid form textarea,
.container_mid form select,
.container_mid form option {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #faa535;
}

.container_mid form button {
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.container_mid form button:hover,
.container_mid form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
  .wrapper {
    display: grid;
  }

  .wrapper > * {
    padding: 2em;
  }

  .brand {
    text-align: left;
  }
}

/*muss noch angepasst werden-----------------------------------------------------------------------------------*/
.pocc_form {
  padding: 25px 0px 0px 0px;
}

.pocc_title {
  font-size: 25px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
