<template>
  <div>
    <img src="../img/NLC new website/vector.png" alt="vector-img" id="vector" />
    <p style="color: #dc6803; padding-top: 5%">{{ $t("About.about") }}</p>
    <b style="font-size: 40px">{{ $t("About.title") }}</b>
    <br /><br /><br />
    <b style="font-size: 30px">{{ $t("About.subtitle") }}</b>
    <br />
    <p style="font-size: 25px">
      {{ $t("About.body") }}
    </p>

    <table>
      <tr>
        <td>
          <img
            src="../img/NLC new website/about.png"
            style="width: 77vw"
            alt=""
            id="aboutimg"
          />
        </td>
      </tr>
    </table>
    <br /><br />

    <b style="font-size: 30px">{{ $t("About.core") }}</b>
    <table id="care">
      <tr>
        <td>
          <img
            src="../img/NLC new website/about3.png"
            alt=""
            style="width: 15vw"
          />
        </td>
        <td>
          <img
            src="../img/NLC new website/about4.png"
            alt=""
            style="width: 20vw"
          />
        </td>
      </tr>
      <tr>
        <td>
          <b style="color: #831b19; font-size: 25px">{{
            $t("About.solutions[0].title")
          }}</b>
        </td>
        <td>
          <b style="color: #831b19; font-size: 25px">{{
            $t("About.solutions[1].title")
          }}</b>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            {{ $t("About.solutions[0].body") }}
          </p>
        </td>
        <td>
          <p>
            {{ $t("About.solutions[1].body") }}
          </p>
        </td>
      </tr>
    </table>
    <br /><br />
    <button
      style="
        background-color: #dc6803;
        color: white;
        padding: 15px;
        border: none;
      "
    >
      <a href="/#problemsDiv" style="color: white">{{ $t("About.button") }}</a>
    </button>
    <br /><br />
    <br />
    <br />
    <div class="about_bg">
      <img
        src="../img/NLC new website/why1.png"
        alt=""
        style="position: absolute; top: -20px; width: 50px"
      />
      <br />
      <h2 style="color: #885f23; font-size: small">
        {{ $t("About.vision[0]") }}
      </h2>
      <p style="font-size: 30px">
        <span style="color: #dc6803">{{ $t("About.vision[1]") }}</span>
        {{ $t("About.vision[2]") }}
      </p>
    </div>

    <br /><br /><br />
    <ContactUs id="contactformAbout" />
  </div>
</template>
<script>
import ContactUs from "@/components/contactUs.vue";
export default {
  components: {
    ContactUs,
  },
  mounted: function () {
    this.goTop();
  },
  methods: {
    goTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
td {
  border: none;
  vertical-align: top;
}
#vector {
  width: 7%;
  height: fit-content;
  position: absolute;
  left: 0;
  top: 10%;
}
#care td {
  width: 50%;
}
.about_bg {
  background-image: url("../img/NLC new website/about_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  position: relative;
  left: -11vw;
  /* font-weight: 600; */
  padding-left: 14%;
  padding-right: 14%;
  padding-top: 2%;
  padding-bottom: 2%;
}
#firstimg {
  width: 38vw;
}
#secondimg {
  width: 38vw;
}
@media screen and (max-width: 860px) {
  #contactformAbout {
    margin: 0;
    padding: 0;
    position: relative;
    left: -12vw;
    width: 98vw;
  }
}
</style>
