<template>
  <div class="footer-wrapper">
    <el-divider />

    <div class="footer">
      <!-- footer 1 -->
      <div class="footer-col">
        <ul class="footer-list">
          <li class="title">{{ $t("FooterTitle[0]") }}</li>
          <!-- footer links -->
          <li>
            <a href="#" @click="routeToHome()" class="footer-list-link">{{
              $t("Header[16]")
            }}</a>
          </li>
          <li>
            <a href="/providers" class="footer-list-link">{{
              $t("Header[0]")
            }}</a>
          </li>
          <li>
            <a href="/schools" class="footer-list-link">{{
              $t("Header[1]")
            }}</a>
          </li>
          <li>
            <a href="/technology" class="footer-list-link">{{
              $t("Header[2]")
            }}</a>
          </li>
          <li>
            <a href="/#SolutionContainer" class="footer-list-link">{{
              $t("Header[3]")
            }}</a>
          </li>
          <li>
            <a href="/whyneuroLeap" class="footer-list-link">{{
              $t("Header[4]")
            }}</a>
          </li>
          <li>
            <a href="/about" class="footer-list-link">{{ $t("Header[5]") }}</a>
          </li>
          <li>
            <a href="/contact" class="footer-list-link">{{
              $t("Header[6]")
            }}</a>
          </li>
        </ul>
      </div>
      <!-- footer 2 -->
      <div class="footer-col">
        <ul class="footer-list">
          <!-- footer title -->
          <li class="title">
            {{ $t("FooterTitle[1]") }}
          </li>
          <!-- footer links -->
          <li>
            <a
              href="http://vividkidsblog.page"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn1[0]") }}</a
            >
          </li>
          <li>
            <a
              href="http://vividkidsblog.page"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn1[1]") }}</a
            >
          </li>
          <li>
            <a
              href="http://vividkidsblog.page"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn1[2]") }}</a
            >
          </li>
          <li>
            <a
              href="http://vividkidsblog.page"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn1[3]") }}</a
            >
          </li>
          <li>
            <a
              href="http://vividkidsblog.page"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn1[4]") }}</a
            >
          </li>
        </ul>
      </div>

      <!-- footer 3 -->
      <div class="footer-col">
        <ul class="footer-list">
          <!-- footer title -->
          <li class="title">
            {{ $t("FooterTitle[2]") }}
          </li>
          <!-- footer links -->
          <!-- link targets are pdf-files in the public-folder. :href instead of href and ${publicPath} used for that reason -->
          <li>
            <a
              :href="`${publicPath}nl-terms-and-conditions.pdf`"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn3[0]") }}</a
            >
          </li>
          <li>
            <a
              :href="`${publicPath}nl-privacy-policy-eu.pdf`"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn3[1]") }}</a
            >
          </li>
          <li>
            <a
              :href="`${publicPath}nl-privacy-policy.pdf`"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn3[2]") }}</a
            >
          </li>
          <li>
            <a
              :href="`${publicPath}nl-cookie-policy.pdf`"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn3[3]") }}</a
            >
          </li>
        </ul>
      </div>
      <!-- footer 4 -->
      <div class="footer-col">
        <ul class="footer-list">
          <!-- footer title -->
          <li class="title">{{ $t("FooterTitle[3]") }}</li>
          <!-- footer links -->
          <li>
            <a
              href="https://www.linkedin.com/company/neuroleap-corp?trk=similar-companies_org_title"
              class="footer-list-link"
              >LinkedIn</a
            >
          </li>
          <li>
            <a
              href="https://www.facebook.com/pages/category/Education/Neuro-Leap-Corp-278266009350133/"
              class="footer-list-link"
              >Facebook</a
            >
          </li>
          <li>
            <a href="https://twitter.com/LeapNeuro" class="footer-list-link"
              >X</a
            >
          </li>
          <li>
            <a href="https://www.threads.net/" class="footer-list-link"
              >Threads</a
            >
          </li>
        </ul>
      </div>

      <!-- footer column 4 -->
      <!-- <div class="footer-col">
        <ul class="footer-list">
          <li class="title">
            {{ $t('FooterTitle[3]') }}
          </li>
          <li>
            <a href="https://www.nvidia.com/" target="_blank" class="footer-list-link">{{ $t('FooterColumn4[0]') }}</a>
          </li>
          <li>
            <a href="https://www.pie.capital/" target="_blank" class="footer-list-link">{{ $t('FooterColumn4[1]') }}</a>
          </li>
          <li>
            <a href="https://myactionspot.com/" target="_blank" class="footer-list-link">{{ $t('FooterColumn4[2]')
            }}</a>
          </li>
        </ul>
      </div> -->
    </div>
    <el-divider />
    <div class="copyright">
      <img
        src="../img/logo_no_r.svg"
        class="footer-logo"
        alt="logo"
        style="float: left; padding-left: 25%"
      />
      © 2015-2024 NeuroLeap Corp. All rights reserved.
      <!-- <a
        :href="`${publicPath}nl-privacy-policy.pdf`"
        target="_blank"
        class="footer-list-link"
        >{{ $t("FooterColumn3[2]") }}</a
      > -->
    </div>
  </div>
</template>

<script>
/* used to path to pdf-files in public-folder */
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    routeToHome: function () {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
.footer-wrapper {
  width: 90%;
  position: relative;
  left: 3.5%;
  text-align: center;
}

.footer-logo {
  height: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.footer-col {
  width: 20%;
  /* text-align: left; */
  text-align: center;

  display: inline-block;
  vertical-align: top;
}

.footer-list {
  list-style-type: none;
}

.title {
  font-weight: bold;
  margin-bottom: 20px;
}

a,
a:link,
a:visited,
a:hover {
  color: black;
}

li {
  margin-bottom: 5px;
}

.copyright {
  font-size: 10px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
