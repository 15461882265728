<template>
  <div>
    <img
      src="../img/NLC new website/vector.png"
      alt="vector-img"
      id="vector"
      style="
        width: 7%;
        height: fit-content;
        position: absolute;
        left: 0;
        top: 10%;
      "
    />
    <p style="color: #dc6803; padding-top: 5%">{{ $t("Schools[0]") }}</p>
    <h1>{{ $t("Schools[1]") }}</h1>
    <br />
    <p style="font-size: 25px">
      {{ $t("Schools[2]") }}
    </p>
    <br />
    <h1>{{ $t("Schools[3]") }}</h1>
    <div>
      <p style="font-size: 25px">
        <img src="../img/NLC new website/schools.png" alt="" id="IMG" />
        {{ $t("Schools[4]") }}
      </p>
      <button
        style="
          background-color: #dc6803;
          color: white;
          padding: 15px;
          border: none;
        "
      >
        <a href="/#SolutionContainer" style="color: white">{{
          $t("Schools[5]")
        }}</a>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  mounted: function () {
    // run automatically
    this.goTop();
    // this.navResponsive();
  },
  methods: {
    goTop() {
      window.scrollTo(0, 0);
    },
    navResponsive: function () {
      // nav-logo
      var x = document.getElementById("myNav-logo");
      if (x.className === "nav-logo") {
        x.className += " responsive";
      } else {
        x.className = "nav-logo";
      }
      // nav-links
      var y = document.getElementById("myNav-links");
      if (y.className === "nav-links") {
        y.className += " responsive";
      } else {
        y.className = "nav-links";
      }
      // nav-buttons
      var z = document.getElementById("myNav-btns");
      if (z.className === "nav-btns") {
        z.className += " responsive";
      } else {
        z.className = "nav-btns";
      }

      // nav-access
      var access = document.getElementById("myNav-access");
      if (access.className === "nav-access dropdown") {
        access.className += " responsive";
      } else {
        access.className = "nav-access dropdown";
      }
      // language
      var lan = document.getElementById("btn-language");
      if (lan.className === "nav-btn btn-language") {
        lan.className += " responsive";
      } else {
        lan.className = "nav-btn btn-language";
      }
    },
  },
};
</script>
<style scoped>
#IMG {
  float: right;
  width: 50%;
  border-radius: 50px;
}
@media screen and (max-width: 860px) {
  /* #imgSection {
    display: flex;
  } */
  #IMG {
    float: left;
    width: 75vw;
  }
}
</style>
