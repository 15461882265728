<template>
  <div class="headline" id="#">
    <br /><br />
    <!-- landing page start -->
    <!-- general page -->
    <div v-if="$route.name != 'fieldTrial'" id="topContent">
      <div>
        <!-- title -->
        <h2 id="headlineTitle">
          <div v-if="$i18n.locale == 'English'">
            {{ $t("Headline['title1']") }}
            <span style="color: #dc6803">{{ $t("Headline['title2']") }}</span>
            {{ $t("Headline['title3']") }}
          </div>
          <div v-else>
            {{ $t("Headline['title']") }}
          </div>
        </h2>
        <br />
        <!-- subtitle -->
        <span id="subtitle">{{ $t("Headline['subtitle']") }}</span>
        <br />
        <!-- contact us -->
        <button id="headlineContact">
          <a href="/#contactus" style="color: white">{{
            $t("Headline['contactus']")
          }}</a>
        </button>
      </div>
      <img
        src="../img/NLC new website/TopOnLandingPage.png"
        id="headline-image-default"
        alt="headline-image"
      />
    </div>
    <!-- field trial -->
    <div v-else>
      <h2>
        <img
          src="../img/happy_hero.png"
          alt="happy_hero"
          width="270"
          height="250"
          class="headline-image-fieldtrial"
        />
        <b>{{ $t("Headline['fieldTrialHeader']") }}</b>
      </h2>
    </div>
    <!-- intro -->
    <div id="headline-section-two">
      {{ $t("Headline['intro']") }}
    </div>
    <!-- core-feature -->
    <div class="container">
      <h2 class="title">{{ $t("Headline['corefeature']") }}</h2>
      <!-- features -->
      <div class="features">
        <div class="feature-box">
          <img
            src="../img/NLC new website/core-feature-1.png"
            alt="AI and Software Integration Icon"
          />
          <p>{{ $t("Headline['feature'][0]") }}</p>
        </div>
        <div class="feature-box">
          <img
            src="../img/NLC new website/core-feature-2.png"
            alt="Real-time Screenings Icon"
          />
          <p>
            {{ $t("Headline['feature'][1]") }}
          </p>
        </div>
        <div class="feature-box">
          <img
            src="../img/NLC new website/core-feature-3.png"
            alt="Personalized Solutions Icon"
          />
          <p>
            {{ $t("Headline['feature'][2]") }}
          </p>
        </div>
      </div>
      <!-- cta -->
      <div class="cta-box">
        <p>
          {{ $t("Headline['cta']") }}
        </p>
        <div class="buttons1">
          <a href="/providers" class="button1">{{
            $t("Headline['forproviders']")
          }}</a>
          <a href="/schools" class="button1">{{
            $t("Headline['forschools']")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    routeToHome: function () {
      if (this.$route.path !== "/") {
        this.$router.push({
          path: "/",
          query: { reload: new Date().getTime() },
        });
      }
    },
  },
};
</script>

<style scoped>
.headline {
  width: 95vw;
}
#topContent {
  display: flex;
}
#headline-section-two {
  background-image: url("../img/NLC new website/bg-header.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 30px;
  padding-left: 23%;
  padding-right: 23%;
  padding-top: 7%;
  padding-bottom: 7%;
  margin: 0;
  width: 100vw;
}
#headline-image-default {
  width: 40vw;
  height: fit-content;
}
#headlineContact {
  background-color: #dc6803;
  color: white;
  padding: 15px;
  margin-top: 15px;
  margin-left: 15vw;
  border: none;
  border-radius: 10px;
}
.headline-image-fieldtrial {
  max-width: 50%;
  margin-right: 5%;
  position: relative;
  float: left;
}

#headlineTitle {
  width: 45vw;
  display: inline-block;
  margin-left: 15vw;
  margin-top: 5%;
  text-decoration: bold;
  font-size: 60px;
}
#vector {
  height: 30%;
  width: fit-content;
  position: relative;
  left: -3%;
  top: -45%;
  display: inline-block;
}

.container {
  /* width: 2000vw; */
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;
  position: relative;
  left: 2vw;
}

.title {
  font-size: 35px;
  margin-bottom: 40px;
  font-weight: 900;
}
#subtitle {
  margin-left: 15vw;
  margin-top: 15px;
  display: inline-block;
  font-size: 20px;
}

.features {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin: 0 auto;
  margin-bottom: 30px;
  flex-wrap: wrap;
  /* width: 100%; */
  /* max-width: 100%; */
}

.feature-box {
  flex: 1 1 0;
  min-width: 250px;
  padding: 35px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
}

.feature-box img {
  width: 100px;
  margin-bottom: 15px;
}

.feature-box p {
  font-size: 20px;
  color: #333;
  font-weight: 600;
}

.cta-box {
  background: linear-gradient(to right, #f0f4fa, #fcfbf7);
  padding: 20px;
  padding-top: 35px;
  padding-bottom: 35px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  /* margin: 0 auto; */
  margin-bottom: 40px;
}

.cta-box p {
  font-size: 20px;
  font-weight: bold;
}

.buttons1 {
  display: flex;
  gap: 10px;
}

.button1 {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #f7901e;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button1:hover {
  background-color: #d46c1d;
}

@media screen and (min-width: 991px) {
  .headline {
    margin-left: -17vw;
  }
}

@media screen and (max-width: 1800px) {
  #vector {
    display: none;
  }
}

@media screen and (max-width: 860px) {
  #headline-image-default {
    width: 100%;
  }
  #headlineTitle {
    width: 60vw;
    font-size: 35px;
    margin-top: -7vh;
    margin-left: 20vw;
    text-align: center;
  }
  #subtitle {
    width: 60vw;
    margin-left: 20vw;
    text-align: center;
  }
  #headlineContact {
    width: 60vw;
    margin-left: 20vw;
  }
  #headline-section-two {
    text-align: center;
    margin-left: -3vw;
  }
  #topContent {
    display: block;
  }
  #vector {
    display: none;
  }
  .container {
    width: 100%;
    align-items: center;
    position: relative;
    left: 0;
  }
}
</style>
