import { render, staticRenderFns } from "./volunteer.vue?vue&type=template&id=babe46be&scoped=true&"
import script from "./volunteer.vue?vue&type=script&lang=js&"
export * from "./volunteer.vue?vue&type=script&lang=js&"
import style0 from "./volunteer.vue?vue&type=style&index=0&id=babe46be&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "babe46be",
  null
  
)

export default component.exports