<template>
  <div>
    <div id="contactus" class="contactUs">
      <div class="container_contact" id="container_contact">
        <div class="container_contact_wrapper">
          <!-- basic company info -->
          <div class="container_left">
            <h1>{{ $t("contact.company") }}</h1>
            {{ $t("contact.intro") }}
          </div>
          <div class="container_right">
            <!-- address -->
            <p>
              {{ $t("contact.address1") }}
              <br />
              {{ $t("contact.address2") }}
            </p>
            <!-- facebook -->
            <a
              href="https://www.facebook.com/pages/category/Education/Neuro-Leap-Corp-278266009350133/"
              target="_blank"
              class="media_name"
              ><img
                src="../img/facebook.png"
                class="icon_socialmedia"
                alt="facebook"
            /></a>
            <!-- linkedin -->
            <a
              href="https://www.linkedin.com/company/neuroleap-corp?trk=similar-companies_org_title"
              target="_blank"
              class="media_name"
              ><img
                src="../img/linkedin.png"
                class="icon_socialmedia"
                alt="linkedin"
            /></a>
            <!-- x -->
            <a
              href="https://twitter.com/LeapNeuro"
              target="_blank"
              class="media_name"
              ><img
                src="../img/NLC new website/x.png"
                class="icon_socialmedia"
                alt="twitter"
            /></a>
            <!-- threads -->
            <a
              href="https://www.threads.net/"
              target="_blank"
              class="media_name"
              ><img
                src="../img/NLC new website/threads.png"
                class="icon_socialmedia"
                alt="threads"
            /></a>
          </div>
        </div>
        <br />

        <!-- content mid: contact form -->
        <div class="container_mid">
          <!-- displayed in mobile only -->
          <table id="hiddenContact">
            <tr>
              <td>
                <!-- contact form button -->
                <div>
                  <h3>{{ $t("contact.form[0][0]") }}</h3>
                </div>
                <br />

                <span>{{ $t("contact.form[0][1]") }}</span>
                <br />
                <el-button
                  id="contactbtn"
                  v-on:click="
                    showContactform();
                    clearOtherForm('contact');
                  "
                  round
                  >{{ $t("contact.form[0][2]") }} below</el-button
                >
              </td>
            </tr>
            <tr>
              <td>
                <!-- field trial button -->
                <div>
                  <h3>{{ $t("contact.form[1][0]") }}</h3>
                </div>
                <br />
                <span>{{ $t("contact.form[1][1]") }}</span>
                <br />
                <el-button id="trialbtn" round>
                  <router-link to="/fieldTrial" style="color: white">{{
                    $t("contact.form[1][2]")
                  }}</router-link>
                </el-button>
              </td>
            </tr>
            <tr>
              <td>
                <!-- newsletter button -->
                <div>
                  <h3>{{ $t("contact.form[2][0]") }}</h3>
                </div>
                <br />
                <span>{{ $t("contact.form[2][1]") }}</span>
                <br />
                <el-button
                  disabled
                  id="newsbtn"
                  v-on:click="
                    showNewsletter();
                    clearOtherForm('news');
                  "
                  round
                  >{{ $t("contact.form[2][2]") }}</el-button
                >
              </td>
            </tr>
          </table>
          <!-- displayed in general screen size only -->
          <table id="shownContact">
            <tr>
              <td>
                <!-- contact form button -->
                <div>
                  <h2>{{ $t("contact.form[0][0]") }}</h2>
                </div>
              </td>
              <td class="gap"></td>
              <td>
                <!-- field trial button -->
                <div>
                  <h2>{{ $t("contact.form[1][0]") }}</h2>
                </div>
              </td>
              <td class="gap"></td>
              <td>
                <!-- newsletter button -->
                <div>
                  <h2>{{ $t("contact.form[2][0]") }}</h2>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span>{{ $t("contact.form[0][1]") }}</span>
              </td>
              <td class="gap"></td>
              <td>
                <span>{{ $t("contact.form[1][1]") }}</span>
              </td>
              <td class="gap"></td>
              <td>
                <span>{{ $t("contact.form[2][1]") }}</span>
              </td>
            </tr>
            <tr>
              <!-- contact us -->
              <td>
                <el-button
                  id="contactbtn"
                  v-on:click="
                    showContactform();
                    clearOtherForm('contact');
                  "
                  round
                  >{{ $t("contact.form[0][2]") }}</el-button
                >
              </td>
              <td class="gap"></td>
              <!-- field trial -->
              <td>
                <el-button id="trialbtn" round>
                  <router-link to="/fieldTrial" style="color: white">{{
                    $t("contact.form[1][2]")
                  }}</router-link>
                </el-button>
              </td>
              <td class="gap"></td>
              <!-- newletter -->
              <td>
                <el-button
                  disabled
                  id="newsbtn"
                  v-on:click="
                    showNewsletter();
                    clearOtherForm('news');
                  "
                  round
                  >{{ $t("contact.form[2][2]") }}</el-button
                >
              </td>
            </tr>
          </table>

          <br />

          <!-- start newsletter -->
          <div>
            <div id="newsletter" class="newsletter" style="display: none">
              <h1>{{ $t("newsletter.heading") }}</h1>
              <div class="container_newsletter">
                <div class="container_mid">
                  <div id="newsletterform">
                    <h1 class="brand">
                      <span>{{ $t("newsletterform.neuro") }}</span>
                      {{ $t("newsletterform.leap") }}
                    </h1>
                    <div class="newsletter_wrapper">
                      <div class="newsletterform">
                        <div class="alert">
                          {{ $t("newsletterform.msg_success") }}
                        </div>
                        <form
                          id="newsletterform"
                          ref="formNews"
                          @submit.prevent="sendEmail('newsletter')"
                        >
                          <!-- firstname -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_firstname") }} *</label
                            >
                            <input
                              name="firstname"
                              v-model="newsletter.firstname"
                              required
                            />
                          </p>
                          <!-- lastname -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_lastname") }} *</label
                            >
                            <input
                              name="lastname"
                              v-model="newsletter.lastname"
                              required
                            />
                          </p>
                          <!-- city -->
                          <p>
                            <label>{{ $t("newsletterform.lbl_city") }} *</label>
                            <input
                              name="city"
                              v-model="newsletter.city"
                              required
                            />
                          </p>
                          <!-- state -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_state") }} *</label
                            >
                            <input
                              name="state"
                              v-model="newsletter.state"
                              required
                            />
                          </p>
                          <!-- country -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_country") }} *</label
                            >
                            <input
                              name="country"
                              v-model="newsletter.country"
                              required
                            />
                          </p>
                          <!-- zipcode -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_zipcode") }} *</label
                            >
                            <input
                              name="zipcode"
                              v-model="newsletter.zip"
                              required
                            />
                          </p>
                          <!-- email -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_email") }} *</label
                            >
                            <input
                              name="email"
                              v-model="newsletter.email"
                              required
                            />
                          </p>
                          <!-- role -->
                          <p class="full">
                            <label>{{ $t("newsletterform.lbl_role") }} *</label>
                            <select
                              v-model="newsletter.role"
                              name="interestgroup"
                              id="interestgroup"
                            >
                              <option>CEO</option>
                              <option>CTO</option>
                              <option>CFO</option>
                              <option>
                                {{ $t("newsletterform.role_law") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_specialist") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_healthcare") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_representive") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_teacher") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_parent") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_PCP") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_press") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_investor") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_volunteer") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_support") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_others") }}
                              </option>
                            </select>
                          </p>

                          <!-- frequency -->
                          <p class="full">
                            <label
                              >{{ $t("newsletterform.frequency") }} *</label
                            >
                            <select
                              v-model="newsletter.frequency"
                              name="frequency"
                              id="frequency"
                            >
                              <option>
                                {{ $t("newsletterform.one_month") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.three_months") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.one_year") }}
                              </option>
                            </select>
                          </p>

                          <!-- submit -->
                          <p>
                            <b-button
                              id="newsSubmit"
                              type="submit"
                              v-b-modal.modal-1
                              class="btn_newsletterform"
                              @click="handleNewsletterForm()"
                            >
                              {{ $t("newsletterform.btn_submit") }}
                            </b-button>
                          </p>
                          <!-- reset -->
                          <p>
                            <b-button
                              round
                              type="reset"
                              class="btn_newsletterform"
                            >
                              {{ $t("newsletterform.btn_reset") }}
                            </b-button>
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <b-modal id="modal-1" v-model="showThankYouModal">
              <h2 style="font-family: 'Mandrope', sans-serif">
                Thank you for your submission!
              </h2>
              <br />
              <p style="font-family: 'Mandrope', sans-serif">
                We will get back to you soon.
              </p>
            </b-modal>
          </div>
          <!-- end newsletter -->
        </div>
        <!-- start contactform -->
        <div id="contactform" style="display: none; width: 100%">
          <div class="contact_wrapper" style="width: 100%">
            <div class="contactform" style="width: 100%">
              <h3>{{ $t("contactform.title") }}</h3>
              <br />
              <div class="alert">{{ $t("contactform.msg_success") }}</div>
              <form
                style="width: 100%"
                ref="formContact"
                @submit.prevent="sendEmail('contact')"
                id="contactForm"
                method="post"
                action="https://www1.ipage.com/scripts/formemail.html"
              >
                <table style="width: 100%">
                  <tr>
                    <td>
                      <!-- firstname -->
                      <label>{{ $t("contactform.lbl_firstname") }} *</label>
                    </td>
                    <td>
                      <!-- lastname -->
                      <label>{{ $t("contactform.lbl_lastname") }} *</label>
                    </td>
                    <td>
                      <!-- prefered name -->
                      <label>{{ $t("contactform.lbl_preferredname") }}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        name="firstname"
                        v-model="contact.firstname"
                        required
                      />
                    </td>
                    <td>
                      <input
                        name="lastname"
                        v-model="contact.lastname"
                        required
                      />
                    </td>
                    <td>
                      <input
                        name="preferredname"
                        v-model="contact.preferredname"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <!-- city -->
                      <label>{{ $t("contactform.lbl_city") }} *</label>
                    </td>

                    <td>
                      <!-- State / Prefecture / Province -->
                      <label>{{ $t("contactform.lbl_state") }} *</label>
                    </td>
                    <td>
                      <!-- Country -->
                      <label>{{ $t("contactform.lbl_country") }} *</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input name="city" v-model="contact.city" required />
                    </td>
                    <td>
                      <input name="state" v-model="contact.state" required />
                    </td>
                    <td>
                      <input
                        name="country"
                        v-model="contact.country"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <!-- Zipcode / Postal code -->
                      <label>{{ $t("contactform.lbl_zipcode") }}*</label>
                    </td>
                    <td>
                      <!-- email -->
                      <label>{{ $t("contactform.lbl_email") }} *</label>
                    </td>

                    <td>
                      <!-- phone number -->
                      <label>{{ $t("contactform.lbl_phone") }} *</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input name="zipcode" v-model="contact.zip" required />
                    </td>
                    <td>
                      <input name="email" v-model="contact.email" required />
                    </td>
                    <td>
                      <input name="phone" v-model="contact.phone" required />
                    </td>
                  </tr>
                </table>
                <table>
                  <!-- role -->
                  <tr>
                    <label>{{ $t("contactform.lbl_role") }}*</label>
                  </tr>
                  <tr>
                    <select
                      required
                      v-model="contact.role"
                      name="interestgroup"
                      id="interestgroup"
                      style="width: 50%"
                    >
                      <option
                        value="Parent / Close relative / Care-taker to a young child"
                      >
                        {{ $t("contactform.role_parent") }}
                      </option>
                      <option value="Potential investor">
                        {{ $t("contactform.role_investor") }}
                      </option>
                      <option value="Potential volunteer">
                        {{ $t("contactform.role_volunteer") }}
                      </option>
                      <option value="Potential supporter / collaborator">
                        {{ $t("contactform.role_supporter") }}
                      </option>
                      <option value="Others">
                        {{ $t("contactform.role_others") }}
                      </option>
                    </select>
                  </tr>
                  <!-- job function -->
                  <tr>
                    <label for="jobFunction">{{
                      $t("contactform.lbl_jobfunction")
                    }}</label>
                  </tr>
                  <tr>
                    <select
                      name="jobFunction"
                      v-model="contact.jobFunction"
                      style="width: 50%"
                    >
                      <option value="Accounting">
                        {{ $t("contactform.jobfunction[0]") }}
                      </option>
                      <option value="Admin & HR, Banking / Finance">
                        {{ $t("contactform.jobfunction[1]") }}
                      </option>
                      <option value="Beauty Care / Health">
                        {{ $t("contactform.jobfunction[2]") }}
                      </option>
                      <option value="Building & Construction">
                        {{ $t("contactform.jobfunction[3]") }}
                      </option>
                      <option value="Design">
                        {{ $t("contactform.jobfunction[4]") }}
                      </option>
                      <option value="E-Commerce">
                        {{ $t("contactform.jobfunction[5]") }}
                      </option>
                      <option value="Education">
                        {{ $t("contactform.jobfunction[6]") }}
                      </option>
                      <option value="Hospitality / F&B">
                        {{ $t("contactform.jobfunction[7]") }}
                      </option>
                      <option value="Information Technology">
                        {{ $t("contactform.jobfunction[8]") }}
                      </option>
                      <option value="Insurance">
                        {{ $t("contactform.jobfunction[9]") }}
                      </option>
                      <option value="Management">
                        {{ $t("contactform.jobfunction[10]") }}
                      </option>
                      <option value="Manufacturing">
                        {{ $t("contactform.jobfunction[11]") }}
                      </option>
                      <option value="Marketing / Public Relations">
                        {{ $t("contactform.jobfunction[12]") }}
                      </option>
                      <option value="Media / Advertising">
                        {{ $t("contactform.jobfunction[13]") }}
                      </option>
                      <option value="Medical Servcie">
                        {{ $t("contactform.jobfunction[14]") }}
                      </option>
                      <option value="Merchandising & Purchasing">
                        {{ $t("contactform.jobfunction[15]") }}
                      </option>
                      <option value="Professional Service">
                        {{ $t("contactform.jobfunction[16]") }}
                      </option>
                      <option value="Property / Rental Estate">
                        {{ $t("contactform.jobfunction[17]") }}
                      </option>
                      <option value="Public Sector / Civil Service">
                        {{ $t("contactform.jobfunction[18]") }}
                      </option>
                      <option value="Sales, CS & Business Development">
                        {{ $t("contactform.jobfunction[19]") }}
                      </option>
                      <option value="Science / R&D">
                        {{ $t("contactform.jobfunction[20]") }}
                      </option>
                      <option value="Transportation & Logistics">
                        {{ $t("contactform.jobfunction[21]") }}
                      </option>
                      <option value="A self-employed Specialist">
                        {{ $t("contactform.jobfunction[22]") }}
                      </option>
                      <option value="A Specialist working for an organization">
                        {{ $t("contactform.jobfunction[23]") }}
                      </option>
                      <option value="Parent/Care-taker of a Child">
                        {{ $t("contactform.jobfunction[24]") }}
                      </option>
                      <option value="Primary Care Physician">
                        {{ $t("contactform.jobfunction[25]") }}
                      </option>
                      <option value="Other">
                        {{ $t("contactform.jobfunction[26]") }}
                      </option>
                    </select>
                  </tr>
                  <!-- job title -->
                  <tr>
                    {{
                      $t("contactform.lbl_jobtitle")
                    }}
                  </tr>
                  <tr>
                    <input
                      type="text"
                      name="jobTitle"
                      v-model="contact.jobTitle"
                      style="width: 50%"
                    />
                  </tr>
                  <!-- job level -->
                  <tr>
                    {{
                      $t("contactform.lbl_joblevel")
                    }}*
                  </tr>
                  <tr>
                    <select
                      name="jobLevel"
                      required
                      v-model="contact.jobLevel"
                      style="width: 50%"
                    >
                      <option value="Executive or senior management(C-suite)">
                        {{ $t("contactform.joblevel[0]") }}
                      </option>
                      <option
                        value="Middle management(director, general manager)"
                      >
                        {{ $t("contactform.joblevel[1]") }}
                      </option>
                      <option
                        value="First-level management(assistant manager, manager, senior manager)"
                      >
                        {{ $t("contactform.joblevel[2]") }}
                      </option>
                      <option value="Intermediate or experienced(senior staff)">
                        {{ $t("contactform.joblevel[3]") }}
                      </option>
                      <option value="Entry-level">
                        {{ $t("contactform.joblevel[4]") }}
                      </option>
                      <option value="Private Party">
                        {{ $t("contactform.joblevel[5]") }}
                      </option>
                    </select>
                  </tr>
                  <!-- message -->
                  <tr>
                    <label>{{ $t("contactform.lbl_message") }}*</label>
                  </tr>
                  <tr>
                    <textarea
                      name="message"
                      rows="5"
                      id="message"
                      required
                      style="width: 100%"
                    ></textarea>
                  </tr>
                </table>

                <!-- submit and reset button -->
                <table>
                  <tr>
                    <!-- submit -->
                    <td>
                      <b-button
                        type="submit"
                        class="btn_contactform"
                        name="submit"
                        value="Submit Now"
                        @click="handleContactForm()"
                      >
                        {{ $t("contactform.btn_submit") }}
                      </b-button>
                    </td>
                    <!-- reset -->
                    <td>
                      <b-button type="reset" class="btn_contactform">
                        <span style="color: black">{{
                          $t("contactform.btn_reset")
                        }}</span>
                      </b-button>
                    </td>
                  </tr>
                </table>
              </form>
            </div>
          </div>
        </div>
        <!-- end contactform -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../axios";
import emailJS from "../services/emailJS";
export default {
  name: "thankYouPage",
  data() {
    return {
      showThankYouModal: false,
      contact: {
        firstname: "",
        lastname: "",
        preferredname: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        email: "",
        phone: "",
        role: "",
        jobFunction: "",
        jobLevel: "",
        jobTitle: "",
      },
      newsletter: {
        firstname: "",
        lastname: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        email: "",
        role: "",
        frequency: "",
      },
    };
  },
  methods: {
    // goal: hide other forms when current form is open
    // param: curr: current form about to be opened
    clearOtherForm: function (curr) {
      var contact = document.getElementById("contactform");
      var news = document.getElementById("newsletter");
      if (curr == "contact") {
        if (news.style.display == "block") {
          news.style.display = "none";
        }
      } else {
        if (contact.style.display == "block") {
          contact.style.display = "none";
        }
      }
    },
    // goal: show contact form and scroll to it automatically
    showContactform: function () {
      var x = document.getElementById("contactform");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
      this.$nextTick(() => {
        x.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    },
    // goal: show newsletter form and scroll to it
    showNewsletter: function () {
      var x = document.getElementById("newsletter");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
      this.$nextTick(() => {
        x.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    },
    // goal: send email for current form
    // param: from: current form
    sendEmail(from) {
      this.showThankYouModal = true;
      if (from == "contact") {
        emailJS.sendEmail(from, this.$refs.formContact);
      } else if (from == "newsletter") {
        emailJS.sendEmail(from, this.$refs.formNews);
      }
    },
    // goal: collect contact info and save in DB
    handleContactForm: function () {
      const formToSubmit = {
        firstname: this.contact.firstname,
        lastname: this.contact.lastname,
        preferredname: this.contact.preferredname,
        city: this.contact.city,
        state: this.contact.state,
        country: this.contact.country,
        zip: this.contact.zip,
        email: this.contact.email,
        phone: this.contact.phone,
        role: this.contact.role,
        jobFunction: this.contact.jobFunction,
        jobLevel: this.contact.jobLevel,
        jobTitle: this.contact.jobTitle,
      };
      axios
        .post("api/contactus", formToSubmit)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // goal: collect newsletter info and save in DB
    // TODO: Not fully implemented
    handleNewsletterForm: function () {
      const formToSubmit = {
        firstname: this.newletter.firstname,
        lastname: this.newletter.lastname,
        city: this.newletter.city,
        state: this.newletter.state,
        country: this.newletter.country,
        zip: this.newletter.zip,
        email: this.newletter.email,
        role: this.newsletter.role,
        frequency: this.newsletter.frequency,
      };
      axios
        .post("api/newsletter", formToSubmit)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
#contactus:target {
  padding-top: 7vh;
}
#contactus {
  width: 92%;
  position: relative;
  left: 3%;
}

#modal-1 .h2 {
  font-family: "Manrope", sans-serif;
}

.field_trial_header {
  background-color: #f4892f;
  color: white;
  padding-left: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
}

#shownContact {
  display: block;
  border-collapse: collapse;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.container_contact {
  width: 100%;
  background-color: white;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

.field_trial_wrapper {
  width: 100%;
  border: 1px solid #dcdfe6;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.field_trial_body {
  width: 100%;
  border: 1px solid #dcdfe6;
}

.container_contact_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.container_left {
  width: 40%;
}
.container_right {
  width: 30%;
  float: right;
}

.container_mid {
  width: 100%;
}

.icon_socialmedia {
  height: 18px;
  padding-right: 5px;
}

h2 {
  margin: 0;
}

a {
  color: black;
}

.el-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

#contactbtn {
  background-color: #dc6803;
  color: white;
}

#trialbtn {
  background-color: #dc6803;
  color: white;
}

#newsbtn {
  border-color: #f4892f;
  color: black;
}

.mail_crypt {
  unicode-bidi: bidi-override;
  direction: rtl;
}

/* CONTACTFORM */

* {
  box-sizing: border-box;
}

body {
  background: #f9c75c;
  color: #f4892f;
  line-height: 1.6;
  padding: 1em;
}

.container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.contact_wrapper {
  box-shadow: 0 0 20px 0 rgba(243, 142, 19, 0.7);
  display: flex;
}

.contact_wrapper > * {
  padding: 1em;
}
#contactform {
  background: #f9c75c;
  display: flex;
  justify-content: center;
}
.contactform table {
  width: 100%;
}

.contactform input {
  border: 2px solid #f9c75c;
  width: 100%;
}
.contactform select {
  border: 2px solid #f9c75c;
}
.contactform textarea {
  border: 2px solid #f9c75c;
}
#contactForm td {
  width: 33%;
}
.btn_contactform {
  width: 100%;
  background-color: #f4892f;
}
/* FORM STYLES */
.container_mid form {
  display: grid;
  grid-gap: 10px;
}

.container_mid form label {
  display: block;
}

.container_mid form p {
  margin: 0;
}

.container_mid form .full {
  grid-column: 1 / 3;
}

.container_mid form button,
.container_mid form input,
.container_mid form textarea,
.container_mid form select,
.container_mid form option {
  width: 100%;
  padding: 1em;
  border: 1px solid #faa535;
}

.container_mid form button {
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.container_mid form button:hover,
.container_mid form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

.field_trial_body form {
  display: grid;
  grid-gap: 10px;
}

.field_trial_body label {
  display: block;
}

.field_trial_body p {
  margin: 0;
}

.field_trial_body .full {
  grid-column: 1 / 3;
}

.field_trial_body form button,
.field_trial_body form input,
.field_trial_body textarea,
.field_trial_body form select,
.field_trial_body form option {
  width: 100%;
  padding: 1em;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
}

.field_trial_body form button {
  background: #f2f2f2;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.field_trial_body form button:hover,
.field_trial_body form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

/* @media screen and (min-width: 1380px) and (max-width: 1680px) {
  .contactUs {
    top: -450px;
    margin-bottom: -450px;
  }
}
@media screen and (max-width: 1380px) {
  .contactUs {
    top: -400px;
    margin-bottom: -400px;
  }
}
@media screen and (max-width: 991px) {
  .contactUs {
    top: -330px;
    margin-bottom: -330px;
  }
}
@media screen and (max-width: 700px) {
  .contactUs {
    top: -250px;
    margin-bottom: -250px;
  }
}
@media screen and (max-width: 1397px) and (min-width: 860px) {
  #contactus:target {
    padding-top: 15vh;
  }
} */

/* LARGE SCREENS */
/* @media (min-width: 700px) {
  .contact_wrapper {
    display: grid;
  }

  .contact_wrapper > * {
    padding: 2em;
  }

  .field_trial_wrapper {
    display: grid;
  }

  .field_trial_wrapper > * {
    padding: 2em;
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
} */

/* @media screen and (max-width: 601px) {
  .container_contact_wrapper {
    display: block;
  }

  .field_trial_wrapper {
    display: block;
  }
} */

.container_newsletter {
  width: 100%;
  background-color: #f4892f;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

.container_mid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  margin: 0;
}

a {
  color: black;
}

.mail_crypt {
  unicode-bidi: bidi-override;
  direction: rtl;
}

/* newsletterform */
* {
  box-sizing: border-box;
}

body {
  background: #f9c75c;
  color: #f4892f;
  line-height: 1.6;
  padding: 1em;
}

.container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.newsletter_wrapper {
  box-shadow: 0 0 20px 0 rgba(243, 142, 19, 0.7);
}

.newsletter_wrapper > * {
  padding: 1em;
}

.newsletterform {
  background: #f9c75c;
}

/* FORM STYLES */
.container_mid form {
  display: grid;
  grid-gap: 10px;
}

.container_mid form label {
  display: block;
}

.container_mid form p {
  margin: 0;
}

.container_mid form .full {
  grid-column: 1 / 3;
}

.container_mid form button,
.container_mid form input,
.container_mid form textarea,
.container_mid form select,
.container_mid form option {
  width: 100%;
  padding: 1em;
  border: 1px solid #faa535;
}

.container_mid form button {
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.container_mid form button:hover,
.container_mid form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

/* LARGE SCREENS */
/* @media (min-width: 700px) {
  .newsletter_wrapper {
    display: grid;
  }

  .newsletter_wrapper > * {
    padding: 2em;
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
} */

/* @media screen and (max-width: 601px) {
  .container_newsletter_wrapper {
    display: block;
  }
} */

.warning {
  color: red;
  font-weight: bolder;
}

td {
  border: none;
  vertical-align: top;
  width: 30%;
}
.gap {
  width: 3.33%;
  background-color: #fff;
}

#hiddenContact {
  display: none;
  border-spacing: 10px;
}
@media screen and (max-width: 860px) {
  #hiddenContact {
    display: block;
    background-color: #f9f9f9;
    border-radius: 10px;
  }
  #shownContact {
    display: none;
  }
}
</style>

<!-- <strong
          >Transform Neurological and Educational Care with NeuroLeap</strong
        >
        <br />
        Our end-to-end AI solutions provide healthcare providers, schools, and
        rehabilitation centers with the tools they need to deliver precision
        care and personalized interventions/specialized lessons. Whether you’re
        a healthcare provider, school, or clinic, NeuroLeap’s integrated
        hardware and software platform can significantly help improve outcomes
        for both patients and students.
        <br />
        <br />

        <strong>What We Offer:</strong>
        <ul>
          <li>
            Integrated AI hardware and software solutions for real-time
            neurological and educational care.
          </li>
          <li>Customizable platforms for hospitals, clinics, and schools.</li>
          <li>
            Third-party marketplace for your
            norm-referenced/criterion-referenced screening, assessment and
            intervention method(s)/tool(s) to shine brighter and generate more
            revenue for you.
          </li>
        </ul> -->
