<template>
  <div
    id="app"
    style="
      resize: horizontal;
      overflow: hidden;
      font-family: 'Manrope', sans-serif;
    "
  >
    <!-- direct invest header -->
    <directInvest_Header
      :usernameDirect="loggedInUser.Username"
      v-if="
        $route.name == 'directinvest' ||
        $route.name == 'directinvest/opportunities' ||
        $route.name == 'directinvest/login' ||
        $route.name == 'directinvest/signup'
      "
    />
    <!-- else, header -->
    <Header v-else :username="loggedInUser.Username" />
    <br />

    <!-- if go to field trial -->
    <el-row v-if="$route.name == 'fieldTrial'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <!-- <Headline /> -->
          <br /><br />
          <br /><br />
          <br /><br />
          <br /><br />
          <!-- <About class="nav-space" /> -->

          <fieldTrialQuestionnaire />
          <br />
          <fieldTrialVideo />
          <br /><br /><br /><br /><br /><br /><br /><br />
          <fieldTrialInfo />
          <br /><br /><br />
          <fieldTrialRegistration />
        </div>
      </el-col>
    </el-row>

    <!-- if go to login -->
    <el-row v-else-if="$route.name == 'login'">
      <div class="content loginAndReg">
        <Login v-on:changeHeaderUponLogin="setHeaderText" />
      </div>
    </el-row>

    <!-- if go to providers -->
    <el-row v-else-if="$route.name == 'providers'">
      <div class="content loginAndReg">
        <Providers />
      </div>
    </el-row>
    <!-- if go to schools -->
    <el-row v-else-if="$route.name == 'schools'">
      <div class="content loginAndReg">
        <Schools />
      </div>
    </el-row>

    <!-- if go to technology -->
    <el-row v-else-if="$route.name == 'technology'">
      <div class="content loginAndReg">
        <Technology />
      </div>
    </el-row>

    <!-- if go to why neuro -->
    <el-row v-else-if="$route.name == 'whyneuroLeap'">
      <div class="content loginAndReg">
        <WhyNeuroLeap />
      </div>
    </el-row>

    <!-- if go to about -->
    <el-row v-else-if="$route.name == 'about'">
      <div class="content loginAndReg">
        <About />
      </div>
    </el-row>

    <!-- if go to contact -->
    <el-row v-else-if="$route.name == 'contact'">
      <div class="content loginAndReg">
        <Contact />
      </div>
    </el-row>

    <!-- if go to register -->
    <el-row v-else-if="$route.name == 'register'">
      <div class="content loginAndReg">
        <Register v-on:changeHeaderUponRegister="setHeaderText" />
      </div>
    </el-row>

    <!-- if go to thankyou -->
    <el-row v-else-if="$route.name == 'thankyoupage'">
      <div class="content">
        <thankYouPage />
      </div>
    </el-row>
    <!-- reset password -->
    <el-row v-else-if="$route.name == 'resetPassword'">
      <div class="content">
        <forgetPassword />
      </div>
    </el-row>

    <!-- if go to user management -->
    <el-row v-else-if="$route.name == 'userManagement'">
      <div class="content loginAndReg">
        <userManagement />
      </div>
    </el-row>

    <!-- if go to registered events -->
    <el-row v-else-if="$route.name == 'registeredEvents'">
      <div class="content loginAndReg">
        <registeredEvents />
      </div>
    </el-row>

    <!-- if go to supportus -->
    <el-row v-else-if="$route.name == 'supportUs'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <supportUs />
        </div>
      </el-col>
    </el-row>

    <!-- if go to partner -->
    <!-- <el-row v-else-if="$route.name == 'partner'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <partner />
        </div>
      </el-col>
    </el-row> -->

    <!-- if go to events -->
    <el-row v-else-if="$route.name == 'events'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <events />
        </div>
      </el-col>
    </el-row>

    <!-- if go to donate -->
    <el-row v-else-if="$route.name == 'donate'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <donate />
        </div>
      </el-col>
    </el-row>

    <!-- if go to directinvest -->
    <el-row v-else-if="$route.name == 'directinvest'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <directInvest />
        </div>
      </el-col>
    </el-row>

    <!-- if go to directinvest/id -->
    <el-row v-else-if="$route.name == 'directinvest/id'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <directInvest_Invest :userInvesting="loggedInUser" />
        </div>
      </el-col>
    </el-row>

    <!-- if go to directinvest/opportunities -->
    <el-row v-else-if="$route.name == 'directinvest/opportunities'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <directInvest_Opportunities :investmentUser="loggedInUser" />
        </div>
      </el-col>
    </el-row>

    <!-- if go to directinvest/login -->
    <el-row v-else-if="$route.name == 'directinvest/login'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <directInvest_Login @loggedIn="newLogin" />
        </div>
      </el-col>
    </el-row>

    <!-- if go to directinvest/signup -->
    <el-row v-else-if="$route.name == 'directinvest/signup'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <directInvest_SignUp />
        </div>
      </el-col>
    </el-row>

    <!-- if go to equity invest -->
    <el-row v-else-if="$route.name == 'equityinvest'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <equityInvest />
        </div>
      </el-col>
    </el-row>

    <!-- if go to refercustomer -->
    <el-row v-else-if="$route.name == 'refercustomer'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <refercustomer />
        </div>
      </el-col>
    </el-row>

    <!-- if go to volunteer -->
    <el-row v-else-if="$route.name == 'volunteer'">
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <volunteer />
        </div>
      </el-col>
    </el-row>

    <!-- else, go home -->
    <el-row v-else>
      <el-col :xs="32" :sm="24" :md="16">
        <div class="content">
          <br /><br /><br />
          <Headline />
          <br />
          <Solutions />
          <Problems class="nav-space" />
          <br />
          <partner class="nav-space" />
          <br />
          <FAQ class="nav-space" />
          <br />
          <ContactUs class="nav-space" />
          <br />
          <br />
          <br />
          <span style="position: absolute; left: 10px"
            >1:
            <a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC6329709/#CR17"
              >40%</a
            ></span
          >
          <br />
          <span style="position: absolute; left: 10px"
            >2:
            <a
              href="https://research.com/universities-colleges/number-of-public-schools-in-the-us#:~:text=There%20are%20130%2C930%20K%2D12%20schools%20in%20the,sources%20coming%20from%20federal%20and%20local%20sources"
              >130,000</a
            >;
            <a
              href="https://educationdata.org/public-education-spending-statistics"
              >$857 billion</a
            >
          </span>
        </div>
      </el-col>
    </el-row>

    <!-- always have footer in any page -->
    <Footer />
  </div>
</template>
<script src="https://www.paypal.com/sdk/js?client-id=Acm_FNoZJuwPQTLbA-0NqylATBlhwanM71KkAGjAb9ryO_yrzsNv5NZgeg2XdHE1Y9IRGasT2KpmLhZm&enable-funding=venmo&currency=USD"></script>

<script>
import Header from "@/components/header.vue";
import Headline from "@/components/headline.vue";
import About from "@/components/about.vue";
// import Products from "@/components/products.vue";
import Problems from "@/components/problems.vue";
// import Research from "@/components/research.vue";
import WhyNeuroLeap from "@/components/whyNeuroLeap.vue";
import Providers from "@/components/providers.vue";
import Schools from "@/components/schools.vue";
import Technology from "@/components/technology.vue";
import Contact from "@/components/contact.vue";

import FAQ from "@/components/faq.vue";
import ContactUs from "@/components/contactUs.vue";
import Login from "@/components/login.vue";
import Register from "@/components/register.vue";
import supportUs from "@/components/supportUs.vue";
import Footer from "@/components/footer.vue";
import fieldTrialRegistration from "@/components/fieldTrialRegistration.vue";
import fieldTrialVideo from "@/components/fieldTrialVideo.vue";
import fieldTrialQuestionnaire from "@/components/fieldTrialQuestionnaire.vue";
import fieldTrialInfo from "@/components/fieldTrialInfo.vue";
import thankYouPage from "@/components/thankYouPage.vue";
import donate from "@/components/donate.vue";
import directInvest from "@/components/directInvest.vue";
import equityInvest from "@/components/equityInvest.vue";
import refercustomer from "@/components/referCustomer.vue";
import volunteer from "@/components/volunteer.vue";
import directInvest_Login from "@/components/directInvest_Login.vue";
import directInvest_Opportunities from "@/components/directInvest_Opportunities.vue";
import directInvest_SignUp from "@/components/directInvest_SignUp.vue";
import directInvest_Header from "@/components/directInvest_Header.vue";
import directInvest_Invest from "@/components/directInvest_Invest.vue";
import partner from "@/components/partner.vue";
import events from "@/components/events.vue";
import userManagement from "@/components/userManage.vue";
import registeredEvents from "@/components/registeredEvents.vue";
import forgetPassword from "@/components/forgetPassword.vue";
import Solutions from "@/components/solutions.vue";

export default {
  name: "App",
  data() {
    return {
      loginText: "Login",
    };
  },
  components: {
    Header,
    Headline,
    userManagement,
    registeredEvents,
    About,
    // Products,
    Problems,
    // Research,
    WhyNeuroLeap,
    Providers,
    Schools,
    Technology,
    Contact,
    thankYouPage,
    FAQ,
    ContactUs,
    Login,
    Register,
    supportUs,
    Footer,
    fieldTrialRegistration,
    fieldTrialVideo,
    fieldTrialQuestionnaire,
    fieldTrialInfo,
    donate,
    directInvest,
    equityInvest,
    refercustomer,
    volunteer,
    directInvest_Login,
    directInvest_Opportunities,
    directInvest_SignUp,
    directInvest_Header,
    directInvest_Invest,
    partner,
    events,
    forgetPassword,
    Solutions,
  },
  mounted() {
    this.initializeCollapsibles();
    // // For Donation
    // let externalScript = document.createElement("script");
    // externalScript.setAttribute(
    //   "src",
    //   "https://www.paypal.com/sdk/js?client-id=Acm_FNoZJuwPQTLbA-0NqylATBlhwanM71KkAGjAb9ryO_yrzsNv5NZgeg2XdHE1Y9IRGasT2KpmLhZm&enable-funding=venmo&currency=USD"
    // );
    // document.head.appendChild(externalScript);
  },
  beforeRouteUpdate(to, from, next) {
    this.initializeCollapsibles(); // Reinitialize collapsibles
    next();
  },
  props: {
    loggedInUser: {
      type: Object,
      default: function () {
        return { Username: "No User" };
      },
    },
  },

  // beforeRouteUpdate(to, from, next) {
  //   next();
  // },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.initializeCollapsibles();
      });
    },
  },
  methods: {
    setHeaderText(theText) {
      this.loginText = theText;
    },
    newLogin: function (data) {
      this.loggedInUser = data;
    },
    initializeCollapsibles() {
      var coll = document.getElementsByClassName("collapsible");
      for (let i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function () {
          this.classList.toggle("active");
          var content = this.nextElementSibling;
          if (content.style.maxHeight) {
            content.style.maxHeight = null;
          } else {
            content.style.maxHeight = content.scrollHeight + "px";
          }
        });
      }
    },
  },
};
</script>

<style>
@import "./assets/css/font.css";

#app {
  font-family: "Roboto";
}

.el-row {
  display: flex;
  justify-content: center;
}

.content {
  margin-top: 70px;
  padding: 10px;
}

.loginAndReg {
  width: 80%;
}

.loginAndReg {
  width: 80%;
}

/* create space so when clicking on nav the content doesn't overlap with the nav */
.nav-space {
  padding-top: 50px;
}

/* style the button that is used to open and close the collapsible content */
.collapsible {
  font-family: "Roboto";
  background-color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  border-radius: 4px;
  padding-right: 20px;
  border: 1px solid #dcdfe6;
}

/* style collapsible content, hidden on default */
.collapsible_content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  text-align: left;
}

/* "plus" sign (+) for the collapsible button */
.collapsible:after {
  content: "+";
  font-weight: bold;
  font-size: 16px;
  color: rgb(195, 195, 195);
  float: right;
  margin-left: 5px;
}

/* "minus" sign (-) for the collapsible button */
.active:after {
  content: "-";
}
</style>
