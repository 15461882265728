<template>
  <div class="problems" id="fieldTrialInfo">
    <!-- Problem and Solution 1 -->
    <table id="hiddenContent">
      <tr>
        <h3
          class="txt-black"
          style="
            float: left;
            margin-left: 15%;
            width: 100px;
            margin-right: 10%;
            margin-top: 5%;
          "
        >
          {{ $t("fieldTrialInfo.statement1") }}
        </h3>
      </tr>
      <tr>
        <b-card class="infoCard">
          <p class="txt-black">
            {{ $t("fieldTrialInfo.ans1") }}
          </p>
        </b-card>
      </tr>
      <tr>
        <h3
          class="txt-black"
          style="
            float: right;
            margin-left: 15%;
            margin-right: 15%;
            width: 100px;
            margin-bottom: 2%;
          "
        >
          {{ $t("fieldTrialInfo.statement2") }}
        </h3>
      </tr>
      <tr>
        <b-card class="infoCard">
          <p class="txt-black">
            {{ $t("fieldTrialInfo.ans2") }}
          </p>
        </b-card>
      </tr>
      <tr>
        <h3
          class="txt-black"
          style="
            float: left;
            margin-left: 15%;
            width: 100px;
            margin-right: 10%;
            margin-top: 3%;
          "
        >
          {{ $t("fieldTrialInfo.statement3") }}
        </h3>
      </tr>
      <tr>
        <b-card class="infoCard">
          <p class="txt-black">
            {{ $t("fieldTrialInfo.ans3") }}
          </p>
        </b-card>
      </tr>
      <tr>
        <h3
          class="txt-black"
          style="
            float: right;
            margin-left: 10%;
            margin-right: 3.5%;
            width: 150px;
          "
        >
          {{ $t("fieldTrialInfo.statement4") }}
        </h3>
      </tr>
      <tr>
        <b-card class="infoCard">
          <p class="txt-black">
            {{ $t("fieldTrialInfo.ans4Pt1") }}
            <a href="nl-privacy-policy.pdf">{{
              $t("fieldTrialInfo.ans4Pt2")
            }}</a
            >.
          </p>
        </b-card>
      </tr>
    </table>

    <div id="shownContent">
      <el-container>
        <el-main>
          <h3
            class="txt-black"
            style="
              float: left;
              margin-left: 15%;
              width: 100px;
              margin-right: 10%;
              margin-top: 5%;
            "
          >
            {{ $t("fieldTrialInfo.statement1") }}
          </h3>
          <b-card class="infoCard">
            <p class="txt-black">
              {{ $t("fieldTrialInfo.ans1") }}
            </p>
          </b-card>
        </el-main>
      </el-container>

      <el-container>
        <el-main>
          <h3
            class="txt-black"
            style="
              float: right;
              margin-left: 15%;
              margin-right: 15%;
              width: 100px;
              margin-bottom: 2%;
            "
          >
            {{ $t("fieldTrialInfo.statement2") }}
          </h3>
          <b-card class="infoCard">
            <p class="txt-black">
              {{ $t("fieldTrialInfo.ans2") }}
            </p>
          </b-card>
        </el-main>
      </el-container>

      <el-container>
        <el-main>
          <h3
            class="txt-black"
            style="
              float: left;
              margin-left: 15%;
              width: 100px;
              margin-right: 10%;
              margin-top: 3%;
            "
          >
            {{ $t("fieldTrialInfo.statement3") }}
          </h3>
          <b-card class="infoCard">
            <p class="txt-black">
              {{ $t("fieldTrialInfo.ans3") }}
            </p>
          </b-card>
        </el-main>
      </el-container>

      <el-container>
        <el-main>
          <h3
            class="txt-black"
            style="
              float: right;
              margin-left: 10%;
              margin-right: 3.5%;
              width: 150px;
            "
          >
            {{ $t("fieldTrialInfo.statement4") }}
          </h3>
          <b-card class="infoCard">
            <p class="txt-black">
              {{ $t("fieldTrialInfo.ans4Pt1") }}
              <a href="nl-privacy-policy.pdf">{{
                $t("fieldTrialInfo.ans4Pt2")
              }}</a
              >.
            </p>
          </b-card>
        </el-main>
      </el-container>

      <br /><br />
      <el-button
        round
        class="text-dark"
        style="text-align: center; box-shadow: 1px 1px 2px #333"
      >
        <b>{{ $t("fieldTrialInfo.shareInfo") }}</b>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "fieldTrialInfo",
};
</script>

<style scoped>
#fieldTrialInfo:target {
  padding-top: 7vh;
}

@media screen and (max-width: 1397px) and (min-width: 860px) {
  #fieldTrialInfo:target {
    padding-top: 15vh;
  }
}

.problems {
  margin: auto;
  clear: both;
  background-image: url("../img/problems_bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.infoCard {
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.el-main {
  width: auto;
}

.txt-black {
  color: black;
}

@media screen and (max-width: 600px) {
  .el-aside {
    width: 100px;
  }
}

#hiddenContent {
  display: none;
}
@media screen and (max-width: 860px) {
  #hiddenContent {
    display: block;
    background-color: #f9f9f9;
    border-radius: 10px;
  }
  #shownContent {
    display: none;
  }
}
</style>
