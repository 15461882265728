<template>
  <div id="whyneuroLeap" class="whyNeuroLeap">
    <img
      src="../img/NLC new website/vector.png"
      alt="vector-img"
      id="vector"
      style="
        width: 7%;
        height: fit-content;
        position: absolute;
        left: 0;
        top: 10%;
      "
    />
    <br /><br />
    <div style="width: 77vw">
      <b style="font-size: 40px">{{ $t("whyNeuroLeap.heading") }}</b>
      <button
        style="
          background-color: #dc6803;
          color: white;
          padding: 15px;
          border: none;
          position: relative;
          float: right;
        "
      >
        <a href="/#SolutionContainer" style="color: white">{{
          $t("whyNeuroLeap.solution")
        }}</a>
      </button>
    </div>

    <br /><br />

    <p style="font-size: 25px">
      {{ $t("whyNeuroLeap.intro") }}
    </p>
    <br />

    <table id="hiddenWhyNeuro">
      <tr>
        <td>
          <img
            src="../img/NLC new website/why1.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px">
            {{ $t("whyNeuroLeap.reason[0].title") }}</b
          >
          <br />
          {{ $t("whyNeuroLeap.reason[0].body") }}
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why2.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px">{{
            $t("whyNeuroLeap.reason[1].title")
          }}</b>
          <br />
          {{ $t("whyNeuroLeap.reason[1].body") }}
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why3.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px">{{
            $t("whyNeuroLeap.reason[2].title")
          }}</b>
          <br />
          {{ $t("whyNeuroLeap.reason[2].body") }}
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why4.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px">{{
            $t("whyNeuroLeap.reason[3].title")
          }}</b>
          <br />
          {{ $t("whyNeuroLeap.reason[3].body") }}
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why5.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px">{{
            $t("whyNeuroLeap.reason[4].title")
          }}</b>
          <br />
          {{ $t("whyNeuroLeap.reason[4].body") }}
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why6.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px">{{
            $t("whyNeuroLeap.reason[5].title")
          }}</b>
          <br />
          {{ $t("whyNeuroLeap.reason[5].body") }}
        </td>
      </tr>
    </table>

    <table id="shownWhyNeuro">
      <tr>
        <td>
          <img
            src="../img/NLC new website/why1.png"
            alt=""
            style="width: 50px"
          />
        </td>
        <td>
          <img
            src="../img/NLC new website/why2.png"
            alt=""
            style="width: 50px"
          />
        </td>
        <td>
          <img
            src="../img/NLC new website/why3.png"
            alt=""
            style="width: 50px"
          />
        </td>
      </tr>
      <tr>
        <td>
          <b style="font-size: 30px">{{
            $t("whyNeuroLeap.reason[0].title")
          }}</b>
        </td>
        <td>
          <b style="font-size: 30px">{{
            $t("whyNeuroLeap.reason[1].title")
          }}</b>
        </td>
        <td>
          <b style="font-size: 30px">{{
            $t("whyNeuroLeap.reason[2].title")
          }}</b>
        </td>
      </tr>
      <tr>
        <td>
          {{ $t("whyNeuroLeap.reason[0].body") }}
        </td>
        <td>
          {{ $t("whyNeuroLeap.reason[1].body") }}
        </td>
        <td>
          {{ $t("whyNeuroLeap.reason[2].body") }}
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why4.png"
            alt=""
            style="width: 50px"
          />
        </td>
        <td>
          <img
            src="../img/NLC new website/why5.png"
            alt=""
            style="width: 50px"
          />
        </td>
        <td>
          <img
            src="../img/NLC new website/why6.png"
            alt=""
            style="width: 50px"
          />
        </td>
      </tr>
      <tr>
        <td>
          <b class="table_title">{{ $t("whyNeuroLeap.reason[3].title") }}</b>
        </td>
        <td>
          <b class="table_title">{{ $t("whyNeuroLeap.reason[4].title") }}</b>
        </td>
        <td>
          <b class="table_title">{{ $t("whyNeuroLeap.reason[5].title") }}</b>
        </td>
      </tr>
      <tr>
        <td>
          {{ $t("whyNeuroLeap.reason[3].body") }}
        </td>
        <td>
          {{ $t("whyNeuroLeap.reason[4].body") }}
        </td>
        <td>
          {{ $t("whyNeuroLeap.reason[5].body") }}
        </td>
      </tr>
    </table>
    <br /><br /><br /><br />
  </div>
</template>
<script>
export default {
  mounted: function () {
    this.goTop();
  },
  methods: {
    goTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
#whyneuroLeap:target {
  padding-top: 7vh;
}

td {
  border: none;
  vertical-align: top;
  width: 33.33vw;
}
.table_title {
  font-size: 30px;
}
#shownWhyNeuro {
  display: block;
}
#hiddenWhyNeuro {
  display: none;
  width: 100%;
}
#hiddenWhyNeuro tr td {
  width: 100vw;
}
@media screen and (max-width: 860px) {
  #whyneuroLeap:target {
    padding-top: 15vh;
  }
  #whyneuroLeap {
    overflow-x: auto;
  }
  #shownWhyNeuro {
    display: none;
  }
  #hiddenWhyNeuro {
    display: block;
  }
}
</style>
